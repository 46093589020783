import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SettingsService } from './shared/services/settings.service';
import { Subscription } from 'rxjs';
import { Language } from './shared/models/enums';
import { NotificationService } from './services/Notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = []

  rtlStyles: any[] = []

  styles: any[] = []

  constructor(private NotificationService: NotificationService,
    private renderer: Renderer2, 
    @Inject(DOCUMENT) private document: Document, private settingsService: SettingsService) {

  }

  ngOnInit(): void {

    this.NotificationService._getTokenDevice();
    this.NotificationService._ListenMassege();
    
    this.subscriptions.push(this.settingsService.language$.subscribe(language => {
      const rtlPluginsCss = this.renderer.createElement('link')
      this.renderer.setAttribute(rtlPluginsCss, 'rel', 'stylesheet')
      this.renderer.setAttribute(rtlPluginsCss, 'href', 'assets/plugins.bundle.rtl.css')

      const rtlStyleCss = this.renderer.createElement('link')
      this.renderer.setAttribute(rtlStyleCss, 'rel', 'stylesheet')
      this.renderer.setAttribute(rtlStyleCss, 'href', 'assets/style.bundle.rtl.css')

      this.rtlStyles.push(rtlPluginsCss)
      this.rtlStyles.push(rtlStyleCss)

      const pluginsCss = this.renderer.createElement('link')
      this.renderer.setAttribute(pluginsCss, 'rel', 'stylesheet')
      this.renderer.setAttribute(pluginsCss, 'href', 'assets/plugins.bundle.css')

      const styleCss = this.renderer.createElement('link')
      this.renderer.setAttribute(styleCss, 'rel', 'stylesheet')
      this.renderer.setAttribute(styleCss, 'href', 'assets/style.bundle.css')

      this.styles.push(pluginsCss)
      this.styles.push(styleCss)

      if (language === Language.AR) {
        this.rtlStyles.forEach(style => this.renderer.removeChild(this.renderer.parentNode(style), style))

        this.styles.forEach(style => this.renderer.removeChild(this.renderer.parentNode(style), style))

        this.rtlStyles.forEach(style => this.renderer.insertBefore(this.document.head, style, this.document.head.children[10]))

        return
      }

      this.rtlStyles.forEach(style => this.renderer.removeChild(this.renderer.parentNode(style), style))

      this.styles.forEach(style => this.renderer.removeChild(this.renderer.parentNode(style), style))

      this.styles.forEach(style => this.renderer.insertBefore(this.document.head, style, this.document.head.children[10]))
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe()
    });
  }
}
