import { Component, OnDestroy, OnInit } from '@angular/core';
import { Statistics } from '../../models/models';
import { Subscription } from 'rxjs';
import { StatisticsService } from '../../services/statistics.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.css'
})
export class StatisticsComponent implements OnInit, OnDestroy {
  statistics: Statistics

  subscriptions: Subscription[] = []

  constructor(private statisticsService: StatisticsService) {
    this.statistics = {
      cancelledOrdersThisMonthCount: 0,
      completedOrdersThisMonthCount: 0,
      currency: '',
      earningsThisMonth: 0,
      mostSoldProducts: [],
      mostSoldProductsThisMonth: [],
      totalCancelledOrdersCount: 0,
      totalCompletedOrdersCount: 0,
      totalEarnings: 0,
      topProducts: []
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subscriptions.push(this.statisticsService.getStatistics().subscribe(value => {
      this.statistics = value
    }))
  }
}
