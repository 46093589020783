import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingsService } from './shared/services/settings.service';
import { CustomTranslateLoader } from './utilities/language-loader/language-loader';

import { IndexComponent } from './components/index/index.component';
import { IndexLayoutComponent } from './components/index-layout/index-layout.component';
import { SidebarMenuAccordionComponent } from './components/sidebar-menu-accordion/sidebar-menu-accordion.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { StoreDetailsComponent } from './components/store-details/store-details.component';
import { SubscriptionsListComponent } from './components/subscriptions-list/subscriptions-list.component';
import { ReviewsListComponent } from './components/reviews-list/reviews-list.component';
import { ManageWorkHoursComponent } from './components/manage-work-hours/manage-work-hours.component';

import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';

export function HttpLoaderFactory() {
  return new CustomTranslateLoader();
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    IndexLayoutComponent,
    SidebarMenuAccordionComponent,
    StatisticsComponent,
    StoreDetailsComponent,
    SubscriptionsListComponent,
    ReviewsListComponent,
    ManageWorkHoursComponent,


  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (settingsService: SettingsService) => {
        return settingsService.currentLocale;
      },
      deps: [SettingsService],
    },
    provideAnimationsAsync(),
  ],
})
export class AppModule {
  constructor(private settingsService: SettingsService) {}
}
