<!--begin::Products-->
<div class="card card-flush mt-8">
    <div id="kt_app_toolbar" class="app-toolbar pt-6 pb-2">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex align-items-stretch">
            <!--begin::Toolbar wrapper-->
            <div class="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center gap-1 me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                        {{ 'shared.reviews' | translate }}</h1>
                    <!--end::Title-->
                    <app-breadcrumb activeRouteName="{{ 'shared.reviews' | translate }}"
                        [navigationRoutes]="[{name: 'shared.storeManagement' | translate, path: null}]"></app-breadcrumb>
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar wrapper-->
        </div>
        <!--end::Toolbar container-->
    </div>
    <!--begin::Reviews-->
    <div class="card card-flush py-4">
        <!--begin::Card header-->
        <div class="card-header">
            <!--begin::Card title-->
            <div class="card-title w-100">
                <h2> {{ 'shared.reviews' | translate }}</h2>
            </div>
            <!--end::Card title-->

            <!--begin::Card title-->
            <div class="row flex-row-fluid justify-content-center my-4 w-100">
                <div class="d-flex col-md-6">
                    <app-date-picker label="{{ 'shared.start' | translate }}"
                        [dateControl]="form.controls.startDate" />
                </div>
                <div class="d-flex col-md-6">
                    <app-date-picker label="{{ 'shared.end' | translate }}"
                        [dateControl]="form.controls.endDate" />
                </div>
                <!--end::Search-->
            </div>

            <!--begin::Card toolbar-->
            <div class="card-toolbar flex-row-fluid justify-content-start gap-5">
                <div class="w-100 mw-150px">
                    <!--begin::Select2-->
                    <select class="form-select form-select-solid" data-control="select2" data-hide-search="true"
                        [formControl]="form.controls.isApproved" data-placeholder="Status"
                        data-kt-ecommerce-product-filter="status">
                        <option *ngFor="let option of status" [value]="option.value">{{
                            'shared.' + option.name |
                            translate }}</option>
                    </select>
                    <!--end::Select2-->
                </div>
                <div class="d-flex align-items-center" (click)="getStoreReviews()">
                    <button class="btn btn-light btn-md">
                        <i class="ki-outline ki-magnifier fs-3 mx-auto"></i>
                    </button>
                </div>
            </div>

            <!--begin::Card toolbar-->
            <div class="w-100 card-toolbar flex-row-fluid justify-content-end">
                <app-rating [rate]="store?.rate ?? 0" />
            </div>
            <!--end::Card toolbar-->

        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-0">
            <!--begin::Table-->
            <table class="table table-row-dashed fs-6 gy-5 my-0" id="kt_ecommerce_add_product_reviews">
                <thead>
                    <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th class="min-w-100px">{{ 'shared.rating' | translate }}</th>
                        <th class="min-w-100px">{{ 'shared.customer' | translate }}</th>
                        <th class="min-w-100px">{{ 'shared.review' | translate }}</th>
                        <th class="min-w-100px text-end fs-7">{{ 'shared.createdAt' |
                            translate }}</th>
                        <th class="min-w-100px">{{ 'shared.accepted' | translate }}</th>
                        <th class="min-w-100px text-end fs-7">{{ 'shared.acceptedAt' |
                            translate }}</th>
                        <th class="min-w-100px text-end fs-7"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let review of reviews.results; let i = index">
                        <td data-order="rating-5">
                            <app-rating [rate]="review.rate" />
                        </td>
                        <td>
                            <app-name-initial-avatar [name]="review.userName" />
                        </td>
                        <td class="text-gray-600 fw-bold">{{ review.review }}</td>
                        <td class="text-end">
                            <span class="fw-semibold text-muted">{{ review.createdAt | date
                                }}</span>
                        </td>
                        <td class="text-end">
                            <app-badge [severity]="review.isApproved ? badgeSeverity.Success : badgeSeverity.Danger"
                                text="{{ review.isApproved ? ('shared.accepted' | translate) : ('shared.notAccepted' | translate)}}" />
                        </td>
                        <td class="text-end">
                            <span class="fw-semibold text-muted">{{ review.approvedAt | date
                                }}</span>
                        </td>
                        <td class="text-end">
                            <a (click)="toggleReviewAction(i)"
                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary hover:cursor-pointer"
                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                <i class="ki-outline ki-down fs-5 ms-1"></i>
                            </a>
                            <!--begin::Menu-->
                            <div [ngClass]="{ show: reviewsActionsToggles[i] }"
                                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                                data-kt-menu="true">
                                <!--begin::Menu item-->

                                <div class="menu-item px-3" *ngIf="!review.isApproved" (click)="approveReview(i)">
                                    <a class="menu-link px-3 text-lg">
                                        {{ "shared.approve" | translate }}
                                    </a>
                                </div>
                                <div class="menu-item px-3" (click)="deleteReview(i)">
                                    <a class="menu-link px-3 text-lg">
                                        {{ "shared.delete" | translate }}
                                    </a>
                                </div>
                                <!--end::Menu item-->
                            </div>
                        </td>
                        <!--end::Menu-->
                    </tr>
                </tbody>
            </table>
            <!--end::Table-->

            <div class="row">
                <app-table-pagination [pageCountControl]="form.controls.pageCount"
                    [pagination]="reviews" (pageChanged)="getStoreReviews()" />
            </div>

        </div>
        <!--end::Card body-->
    </div>
    <!--end::Reviews-->
</div>