import { Component, Input } from '@angular/core';
import { MenuAccordionItem } from './menu-accordion-item';

@Component({
  selector: 'app-sidebar-menu-accordion',
  templateUrl: './sidebar-menu-accordion.component.html',
  styleUrl: './sidebar-menu-accordion.component.css',
})
export class SidebarMenuAccordionComponent {
  @Input() isOpen: boolean = false;

  @Input() title: string = '';

  @Input() iconClass: string = '';

  @Input() menuItems: MenuAccordionItem[] = [];

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
