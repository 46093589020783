import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MenuAccordionItem } from '../sidebar-menu-accordion/menu-accordion-item';
import { SettingsService } from '../../shared/services/settings.service';
import { Theme } from '../../shared/models/enums';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StoresService } from './../../shared/services/stores.service';

@Component({
  selector: 'app-index-layout',
  templateUrl: './index-layout.component.html',
  styleUrl: './index-layout.component.css',
})
export class IndexLayoutComponent implements OnInit, OnDestroy {
  private bodyAttributes: { [key: string]: string }[] = [{ 'id': 'kt_app_body' },
  { 'data-kt-app-header-fixed': 'true' },
  { 'data-kt-app-header-fixed-mobile': 'true' },
  { 'data-kt-app-sidebar-enabled': 'true' },
  { 'data-kt-app-sidebar-fixed': 'true' },
  { 'data-kt-app-sidebar-hoverable': 'true' },
  { 'data-kt-app-sidebar-push-toolbar': 'true' },
  { 'data-kt-app-sidebar-push-footer': 'true' },
  { 'data-kt-app-aside-enabled': 'true' },
  { 'data-kt-app-aside-fixed': 'true' },
  { 'data-kt-app-aside-push-toolbar': 'true' },
  { 'data-kt-app-aside-push-footer': 'true' }]

  sideBarOpen = true

  mobileSideBarOpen = false

  storeManagementAccordionItems: MenuAccordionItem[] = []

  profileManagementAccordionItems: MenuAccordionItem[] = []

  subscriptions: Subscription[] = []

  storeSlug: string = ''

  constructor(private storesService: StoresService, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private settingsService: SettingsService, private authenticationService: AuthenticationService, private translateService: TranslateService) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'app-default')

    this.bodyAttributes.forEach((attr) => {
      const attrKey = Object.keys(attr)[0]
      this.renderer.setAttribute(this.document.body, attrKey, attr[attrKey])
    })

    this.subscriptions.push(this.translateService.get('shared.store').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/store', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.workHours').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/work-hours', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.orders').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/orders', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.subscriptions').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/subscriptions', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.reviews').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/reviews', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.categories').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/categories', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.products').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/products', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.voucherCodes').subscribe(value => {
      this.storeManagementAccordionItems = [...this.storeManagementAccordionItems, { name: value, url: '/dashboard/voucher-codes', matchExact: false }]
    }))

    this.subscriptions.push(this.translateService.get('shared.profile').subscribe(value => {
      this.profileManagementAccordionItems = [...this.profileManagementAccordionItems, { name: value, url: '/dashboard/profile', matchExact: true }]
    }))

    this.subscriptions.push(this.translateService.get('shared.changePassword').subscribe(value => {
      this.profileManagementAccordionItems = [...this.profileManagementAccordionItems, { name: value, url: '/dashboard/profile/change-password', matchExact: true }]
    }))

    this.subscriptions.push(this.storesService.getStoreDetails().subscribe(value => {
      this.storeSlug = value.slug
    }))
  }

  sidebarMobileToggle() {
    this.mobileSideBarOpen = !this.mobileSideBarOpen

    if (this.mobileSideBarOpen) {
      this.renderer.setAttribute(this.document.body, 'data-kt-drawer-app-sidebar', 'on')
      this.renderer.setAttribute(this.document.body, 'data-kt-drawer', 'on')
      return
    }

    this.renderer.removeAttribute(this.document.body, 'data-kt-drawer-app-sidebar')
    this.renderer.removeAttribute(this.document.body, 'data-kt-drawer')
    this.renderer.removeAttribute(this.document.body, 'data-kt-app-sidebar-minimize')
  }

  sidebarToggle() {
    this.sideBarOpen = !this.sideBarOpen

    if (this.sideBarOpen) {
      this.renderer.setAttribute(this.document.body, 'data-kt-app-sidebar-minimize', 'on')

      return
    }

    this.renderer.removeAttribute(this.document.body, 'data-kt-app-sidebar-minimize')
    this.renderer.removeAttribute(this.document.body, 'data-kt-drawer-app-sidebar')
    this.renderer.removeAttribute(this.document.body, 'data-kt-drawer')
  }

  toggleTheme() {
    const theme = this.settingsService.currentTheme

    if (theme === Theme.DARK) {
      this.settingsService.updateTheme(Theme.LIGHT)

      return
    }

    this.settingsService.updateTheme(Theme.DARK)
  }

  logout() {
    this.authenticationService.logout()
  }
}
