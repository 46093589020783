import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DEFAULT_PAGE_COUNT_DASHBOARD, GetStoreReviewsForm, GetStoreReviewsRequest, initialPaginatedResult, PaginatedResult, StoreDetails, StoreReviewDto } from '../../shared/models/models';
import { Subscription } from 'rxjs';
import { BadgeSeverity, ToastSeverity } from '../../shared/models/enums';
import { ToastService } from '../../shared/services/toast.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoresService } from '../../shared/services/stores.service';
import { DeleteWarningComponent } from '../../shared/components/delete-warning/delete-warning.component';
import { toDateOnlyString } from '../../utilities/date-utilities';

@Component({
  selector: 'app-reviews-list',
  templateUrl: './reviews-list.component.html',
  styleUrl: './reviews-list.component.css'
})
export class ReviewsListComponent implements OnInit, OnDestroy {
  form!: FormGroup<GetStoreReviewsForm>

  subscriptions: Subscription[] = []

  store?: StoreDetails

  status: { value: boolean | null, name: string }[] = [
    { value: null, name: "all" },
    { value: true, name: "accepted" },
    { value: false, name: "notAccepted" }
  ]

  reviews: PaginatedResult<StoreReviewDto> = initialPaginatedResult

  badgeSeverity = BadgeSeverity

  reviewsActionsToggles: boolean[] = []

  constructor(private toastService: ToastService, private modalService: NgbModal, private storesService: StoresService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      endDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false }),
      startDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false }),
      isApproved: new FormControl<boolean | null>(null, { nonNullable: false }),
      pageCount: new FormControl<number>(DEFAULT_PAGE_COUNT_DASHBOARD, { nonNullable: true })
    })

    this.getStoreReviews()

    this.subscriptions.push(this.storesService.getStoreDetails().subscribe(value => {
      this.store = value
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  getStoreReviews() {
    const request: GetStoreReviewsRequest = {
      ascending: false,
      endDate: this.form.controls.endDate.value === null ? null : toDateOnlyString(this.form.controls.endDate.value),
      startDate: this.form.controls.startDate.value === null ? null : toDateOnlyString(this.form.controls.startDate.value),
      isApproved: this.form.controls.isApproved.value,
      pageCount: this.form.controls.pageCount.value,
      pageIndex: this.reviews.pageIndex
    }

    this.subscriptions.push(this.storesService.getStoreReviews(request).subscribe(value => {
      this.reviews = value

      const toggles = []

      for (let i = 0; i < value.results.length; i++) {
        toggles.push(false)
      }

      this.reviewsActionsToggles = toggles
    }))
  }

  toggleReviewAction(index: number) {
    this.reviewsActionsToggles = this.reviewsActionsToggles.map((value, valueIndex) => {
      if (valueIndex === index) {
        return !value
      }

      return value
    })
  }

  deleteReview(index: number) {
    const review = this.reviews.results[index]

    const modalRef = this.modalService.open(DeleteWarningComponent)

    modalRef.componentInstance.confirmed.subscribe(() => {
      this.subscriptions.push(this.storesService.deleteReview(review.id).subscribe(response => {
        this.getStoreReviews()

        this.toastService.addMessage({ message: response.message, severity: ToastSeverity.Success })

        modalRef.close()
      }))
    })
  }

  approveReview(index: number) {
    const review = this.reviews.results[index]

    this.subscriptions.push(this.storesService.approveReview(review.id).subscribe(response => {
      this.getStoreReviews()

      this.toastService.addMessage({ message: response.message, severity: ToastSeverity.Success })
    }))
  }

}
