<!--begin::Content wrapper-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-fluid justify-center">

            <div class="row g-5 gx-xl-10">
                <div class="col-md-4 d-flex align-items-stretch">

                    <!--begin::List widget 6-->
                    <div class="card card-flush h-md-100 w-full">
                        <!--begin::Header-->
                        <div class="card-header pt-7">
                            <!--begin::Title-->
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'index.mostSoldProducts' | translate }}</span>
                            </h3>
                            <!--end::Title-->
                            <!--begin::Toolbar-->
                            <div class="card-toolbar">
                                <a routerLink="/dashboard/products" class="btn btn-sm btn-light">{{ 'shared.viewAll' | translate
                                    }}</a>
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body pt-4">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                                <!--begin::Table-->
                                <table class="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                    <!--begin::Table head-->
                                    <thead>
                                        <tr class="fs-7 fw-bold text-gray-500 border-bottom-0">
                                            <th class="p-0 w-50px pb-1">{{ 'shared.product' |
                                                translate }}</th>
                                            <th class="ps-0 min-w-140px"></th>
                                            <th class="text-end min-w-140px p-0 pb-1">{{ 'shared.price'
                                                | translate }}</th>
                                            <th class="text-end min-w-140px p-0 pb-1"> {{'shared.rating' | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <!--end::Table head-->
                                    <!--begin::Table body-->
                                    <tbody>
                                        <tr *ngFor="let soldProduct of statistics.mostSoldProducts">
                                            <td>
                                                <a routerLink="{{ '/dashboard/products/' + soldProduct.id }}">
                                                    <img [src]="soldProduct.image" class="w-75px h-50px object-fill"
                                                        alt="" />
                                                </a>
                                            </td>
                                            <td class="ps-0 text-center">
                                                <a
                                                    class="text-gray-800 fw-bold text-hover-primary mb-1 fs-8 text-start pe-0">{{
                                                    soldProduct.title }}</a>
                                            </td>
                                            <td>
                                                <div class="d-flex flex-row">
                                                    <span class="text-gray-800 fw-bold d-block fs-8 ps-0 text-end">{{
                                                        soldProduct.price | shortNumber }}</span>
                                                    <span
                                                        class="text-gray-500 fw-semibold fs-8 d-block text-start ps-0">{{
                                                        statistics.currency }}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <app-rating [rate]="soldProduct.rate" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!--end::Table body-->
                                </table>
                            </div>
                            <!--end::Table-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::List widget 6-->

                </div>

                <div class="col-md-4 d-flex align-items-stretch">

                    <!--begin::List widget 6-->
                    <div class="card card-flush h-md-100 w-full">
                        <!--begin::Header-->
                        <div class="card-header pt-7">
                            <!--begin::Title-->
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'index.mostSoldProducts' | translate }}</span>
                                <span class="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">({{
                                    'shared.thisMonth' | translate }})</span>
                            </h3>
                            <!--end::Title-->
                            <!--begin::Toolbar-->
                            <div class="card-toolbar">
                                <a routerLink="/dashboard/products" class="btn btn-sm btn-light">{{ 'shared.viewAll' | translate
                                    }}</a>
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body pt-4">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                                <!--begin::Table-->
                                <table class="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                    <!--begin::Table head-->
                                    <thead>
                                        <tr class="fs-7 fw-bold text-gray-500 border-bottom-0">
                                            <th class="p-0 w-50px pb-1">{{ 'shared.product' |
                                                translate }}</th>
                                            <th class="ps-0 min-w-140px"></th>
                                            <th class="text-end min-w-140px p-0 pb-1">{{ 'shared.price'
                                                | translate }}</th>
                                            <th class="text-end min-w-140px p-0 pb-1"> {{'shared.rating' | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <!--end::Table head-->
                                    <!--begin::Table body-->
                                    <tbody>
                                        <tr *ngFor="let soldProduct of statistics.mostSoldProductsThisMonth">
                                            <td>
                                                <a routerLink="{{ '/dashboard/products/' + soldProduct.id }}">
                                                    <img [src]="soldProduct.image" class="w-75px h-50px object-fill"
                                                        alt="" />
                                                </a>
                                            </td>
                                            <td class="ps-0 text-center">
                                                <a
                                                    class="text-gray-800 fw-bold text-hover-primary mb-1 fs-8 text-start pe-0">{{
                                                    soldProduct.title }}</a>
                                            </td>
                                            <td>
                                                <div class="d-flex flex-row">
                                                    <span class="text-gray-800 fw-bold d-block fs-8 ps-0 text-end">{{
                                                        soldProduct.price | shortNumber }}</span>
                                                    <span
                                                        class="text-gray-500 fw-semibold fs-8 d-block text-start ps-0">{{
                                                        statistics.currency }}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <app-rating [rate]="soldProduct.rate" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!--end::Table body-->
                                </table>
                            </div>
                            <!--end::Table-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::List widget 6-->

                </div>

                <div class="col-md-4 d-flex align-items-stretch">

                    <!--begin::List widget 6-->
                    <div class="card card-flush h-md-100 w-full">
                        <!--begin::Header-->
                        <div class="card-header pt-7">
                            <!--begin::Title-->
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'index.topProducts' | translate }}</span>
                            </h3>
                            <!--end::Title-->
                            <!--begin::Toolbar-->
                            <div class="card-toolbar">
                                <a routerLink="/dashboard/products" class="btn btn-sm btn-light">{{ 'shared.viewAll' | translate }}</a>
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body pt-4">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                                <!--begin::Table-->
                                <table class="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                    <!--begin::Table head-->
                                    <thead>
                                        <tr class="fs-7 fw-bold text-gray-500 border-bottom-0">
                                            <th class="p-0 w-50px pb-1">{{ 'shared.product' |
                                                translate }}</th>
                                            <th class="ps-0 min-w-140px"></th>
                                            <th class="text-end min-w-140px p-0 pb-1">{{ 'shared.price'
                                                | translate }}</th>
                                            <th class="text-end min-w-140px p-0 pb-1">{{ 'shared.rating'
                                                | translate }}</th>
                                        </tr>
                                    </thead>
                                    <!--end::Table head-->
                                    <!--begin::Table body-->
                                    <tbody>
                                        <tr *ngFor="let topProduct of statistics.topProducts">
                                            <td>
                                                <a routerLink="{{ '/dashboard/products/' + topProduct.id }}">
                                                    <img [src]="topProduct.image" class="w-75px h-50px object-fill" />
                                                </a>

                                            </td>
                                            <td class="ps-0 text-center">
                                                <a
                                                    class="text-gray-800 fw-bold text-hover-primary mb-1 fs-8 text-start pe-0">{{
                                                    topProduct.title }}</a>
                                            </td>
                                            <td>
                                                <div class="d-flex flex-row gap-x-1">
                                                    <span class="text-gray-800 fw-bold d-block fs-8 ps-0 text-end">{{
                                                        topProduct.price | shortNumber }}</span>
                                                    <span
                                                        class="text-gray-500 fw-semibold fs-8 d-block text-start ps-0">{{
                                                        statistics.currency }}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <app-rating [rate]="topProduct.rate" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!--end::Table body-->
                                </table>
                            </div>
                            <!--end::Table-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::List widget 6-->

                </div>

                <div class="col-md-3 d-flex align-items-stretch">
                    <!--begin::Card widget 7-->
                    <div class="card card-flush h-md-10 mb-xl-10 w-full">
                        <div class="d-flex flex-row justify-start mt-4 ms-2">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'index.earnings' | translate }}</span>
                            </h3>
                        </div>
                        <!--begin::Card body-->
                        <div class="card-body d-flex flex-column px-0 pb-0">
                            <!--begin::Statistics-->
                            <div class="mb-4 px-9">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center mb-2 justify-start"
                                    *ngIf="statistics.totalEarnings !== 0">
                                    <!--begin::Value-->
                                    <span class="text-2xl fw-bold text-gray-800 me-2">{{
                                        statistics.totalEarnings| shortNumber }}</span>
                                    <span class="text-gray-500 fw-semibold fs-6 d-block ps-0">{{
                                        statistics.currency }}</span>
                                    <!--end::Value-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <!--end::Statistics-->
                        </div>
                        <!--end::Card body-->

                    </div>
                    <!--end::Card widget 7-->
                </div>


                <div class="col-md-3 d-flex align-items-stretch">
                    <!--begin::Card widget 7-->
                    <div class="card card-flush h-md-10 mb-xl-10 w-full">
                        <div class="d-flex flex-row justify-start mt-4 ms-2">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'index.earnings' | translate }}</span>
                                <span class="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">({{
                                    'shared.thisMonth' | translate }})</span>
                            </h3>
                        </div>
                        <!--begin::Card body-->
                        <div class="card-body d-flex flex-column px-0 pb-0">
                            <!--begin::Statistics-->
                            <div class="mb-4 px-9">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center mb-2 justify-start"
                                    *ngIf="statistics.earningsThisMonth !== 0">
                                    <!--begin::Value-->
                                    <span class="text-2xl fw-bold text-gray-800 me-2">{{
                                        statistics.earningsThisMonth| shortNumber }}</span>
                                    <span class="text-gray-500 fw-semibold fs-6 d-block ps-0">{{
                                        statistics.currency }}</span>
                                    <!--end::Value-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <!--end::Statistics-->
                        </div>
                        <!--end::Card body-->

                    </div>
                    <!--end::Card widget 7-->
                </div>



                <div class="col-md-3 d-flex align-items-stretch">
                    <!--begin::Card widget 7-->
                    <div class="card card-flush h-md-10 mb-xl-10 w-full">
                        <div class="d-flex flex-row justify-start mt-4 ms-2">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'shared.completedOrdersCount' | translate }}</span>
                            </h3>
                        </div>
                        <!--begin::Card body-->
                        <div class="card-body d-flex flex-column px-0 pb-0">
                            <!--begin::Statistics-->
                            <div class="mb-4 px-9">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center mb-2 justify-start">
                                    <!--begin::Value-->
                                    <span class="text-2xl fw-bold text-gray-800 me-2">{{
                                        statistics.totalCompletedOrdersCount| shortNumber }}</span>
                                    <!--end::Value-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <!--end::Statistics-->
                        </div>
                        <!--end::Card body-->

                    </div>
                    <!--end::Card widget 7-->
                </div>

                <div class="col-md-3 d-flex align-items-stretch">
                    <!--begin::Card widget 7-->
                    <div class="card card-flush h-md-10 mb-xl-10 w-full">
                        <div class="d-flex flex-row justify-start mt-4 ms-2">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'shared.completedOrdersCount' | translate }}</span>
                                <span class="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">({{
                                    'shared.thisMonth' | translate }})</span>
                            </h3>
                        </div>
                        <!--begin::Card body-->
                        <div class="card-body d-flex flex-column px-0 pb-0">
                            <!--begin::Statistics-->
                            <div class="mb-4 px-9">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center mb-2 justify-start">
                                    <!--begin::Value-->
                                    <span class="text-2xl fw-bold text-gray-800 me-2">{{
                                        statistics.completedOrdersThisMonthCount| shortNumber }}</span>
                                    <!--end::Value-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <!--end::Statistics-->
                        </div>
                        <!--end::Card body-->

                    </div>
                    <!--end::Card widget 7-->
                </div>

                <div class="col-md-3 d-flex align-items-stretch">
                    <!--begin::Card widget 7-->
                    <div class="card card-flush h-md-10 mb-xl-10 w-full">
                        <div class="d-flex flex-row justify-start mt-4 ms-2">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'shared.cancelledOrdersCount' | translate }}</span>
                            </h3>
                        </div>
                        <!--begin::Card body-->
                        <div class="card-body d-flex flex-column px-0 pb-0">
                            <!--begin::Statistics-->
                            <div class="mb-4 px-9">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center mb-2 justify-start">
                                    <!--begin::Value-->
                                    <span class="text-2xl fw-bold text-gray-800 me-2">{{
                                        statistics.totalCancelledOrdersCount| shortNumber }}</span>
                                    <!--end::Value-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <!--end::Statistics-->
                        </div>
                        <!--end::Card body-->

                    </div>
                    <!--end::Card widget 7-->
                </div>


                <div class="col-md-3 d-flex align-items-stretch">
                    <!--begin::Card widget 7-->
                    <div class="card card-flush h-md-10 mb-xl-10 w-full">
                        <div class="d-flex flex-row justify-start mt-4 ms-2">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bold text-gray-800">{{
                                    'shared.cancelledOrdersCount' | translate }}</span>
                                <span class="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">({{
                                    'shared.thisMonth' | translate }})</span>
                            </h3>
                        </div>
                        <!--begin::Card body-->
                        <div class="card-body d-flex flex-column px-0 pb-0">
                            <!--begin::Statistics-->
                            <div class="mb-4 px-9">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center mb-2 justify-start">
                                    <!--begin::Value-->
                                    <span class="text-2xl fw-bold text-gray-800 me-2">{{
                                        statistics.cancelledOrdersThisMonthCount| shortNumber }}</span>
                                    <!--end::Value-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <!--end::Statistics-->
                        </div>
                        <!--end::Card body-->

                    </div>
                    <!--end::Card widget 7-->
                </div>

            </div>
        </div>
        <!--end::Content container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Content wrapper-->