import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Statistics } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private httpClient: HttpClient) {

  }

  getStatistics() {
    return this.httpClient.get<Statistics>(environment.url + 'stores/statistics');
  }
}
