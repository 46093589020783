<!--begin::Products-->
<div class="card card-flush mt-8">
    <div id="kt_app_toolbar" class="app-toolbar pt-6 pb-2">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex align-items-stretch">
            <!--begin::Toolbar wrapper-->
            <div class="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center gap-1 me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                        {{ 'shared.subscriptions' | translate }}</h1>
                    <!--end::Title-->
                    <app-breadcrumb activeRouteName="{{ 'shared.subscriptions' | translate }}"
                        [navigationRoutes]="[{name: 'shared.storeManagement' | translate, path: null}]"></app-breadcrumb>
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar wrapper-->
        </div>
        <!--end::Toolbar container-->
    </div>
    <!--begin::Products-->
    <div class="card card-flush mt-8">
        <!--begin::Card header-->
        <div class="card-header py-5 gap-2 gap-md-5 mt-6">
            <!--begin::Card title-->
            <div class="row flex-row-fluid justify-content-center w-100">
                <div class="d-flex col-md-6">
                    <app-date-picker label="{{ 'shared.start' | translate }}"
                        [dateControl]="form.controls.startDate" />
                </div>
                <div class="d-flex col-md-6">
                    <app-date-picker label="{{ 'shared.end' | translate }}"
                        [dateControl]="form.controls.endDate" />
                </div>
                <!--end::Search-->
            </div>
            <!--begin::Card toolbar-->
            <div class="card-toolbar flex-row-fluid justify-content-start gap-5">
                <div class="w-100 mw-150px">
                    <!--begin::Select2-->
                    <select class="form-select form-select-solid" data-control="select2" data-hide-search="true"
                        [formControl]="form.controls.status" data-placeholder="Status"
                        data-kt-ecommerce-product-filter="status">
                        <option *ngFor="let option of status" [value]="option.value">{{
                            'shared.' + option.name |
                            translate }}</option>
                    </select>
                    <!--end::Select2-->
                </div>
                <div class="d-flex align-items-center" (click)="getSubscriptions()">
                    <button class="btn btn-light btn-md">
                        <i class="ki-outline ki-magnifier fs-3 mx-auto"></i>
                    </button>
                </div>
            </div>

            <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-0 overflow-x-scroll">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
                <thead>
                    <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th class="min-w-100px">{{'shared.startAt' | translate }}</th>
                        <th class="text-end min-w-100px">{{'shared.endAt' | translate }}
                        </th>
                        <th class="text-end min-w-100px">{{ 'shared.price' | translate }}</th>
                        <th class="text-end min-w-100px"> {{ 'shared.isPaid' | translate }}
                        </th>
                        <th class="text-end min-w-100px"> {{ 'shared.paidAt' | translate }}
                        </th>
                        <th class="text-end min-w-100px"> {{ 'shared.status' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                    <tr *ngFor="let subscription of storeSubscriptions.results let i = index">
                        <td>
                            <div class="d-flex align-items-center">
                                <!--end::Thumbnail-->
                                <span class="fw-bold">{{ subscription.startAt | date }}</span>
                            </div>
                        </td>
                        <td class="text-end pe-0">
                            <span class="fw-bold">{{ subscription.endAt | date }}</span>
                        </td>
                        <td class="text-end pe-0" data-order="13">
                            <span class="fw-bold ms-3">{{ subscription.price | shortNumber
                                }}</span>
                            <span class="text-muted">{{ subscription.currencyCode }}</span>
                        </td>
                        <td class="text-end pe-0">
                            <app-badge [severity]="subscription.isPaid ? badgeSeverity.Success : badgeSeverity.Danger"
                                text="{{ subscription.isPaid ? ('shared.isPaid' | translate ) : ('shared.notPaid' | translate)}}" />
                        </td>
                        <td class="text-end pe-0">
                            <span class="fw-bold ms-3">{{ subscription.paidAt | date }}</span>
                        </td>
                        <td class="text-end pe-0">
                            <app-badge
                                [severity]="subscription.status === 'Valid' ? badgeSeverity.Success : badgeSeverity.Danger"
                                text="{{ subscription.status === 'Valid' ? ('shared.valid' | translate) : ('shared.expired' | translate)}}" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--end::Table-->

            <div class="row">
                <app-table-pagination (pageChanged)="getSubscriptions()"
                    [pageCountControl]="form.controls.pageCount" [pagination]="storeSubscriptions" />
            </div>

        </div>
        <!--end::Card body-->
    </div>
    <!--end::Products-->
</div>
<!--end::Products-->