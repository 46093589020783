import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DEFAULT_PAGE_COUNT_DASHBOARD, GetStoreSubscriptionsRequest, initialPaginatedResult, PaginatedResult, StoreDetailsSubscriptionsFilterForm, StoreSubscriptionDto, StoreSubscriptionSearchField, StoreSubscriptionSortBy, StoreSubscriptionStatus } from '../../shared/models/models';
import { BadgeSeverity } from '../../shared/models/enums';
import { StoresService } from '../../shared/services/stores.service';
import { Subscription } from 'rxjs';
import { toDateOnlyString } from '../../utilities/date-utilities';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrl: './subscriptions-list.component.css'
})
export class SubscriptionsListComponent implements OnInit, OnDestroy {
  form!: FormGroup<StoreDetailsSubscriptionsFilterForm>

  status: { value: StoreSubscriptionStatus, name: string }[] = [
    { value: StoreSubscriptionStatus.All, name: StoreSubscriptionStatus.All },
    { value: StoreSubscriptionStatus.Expired, name: StoreSubscriptionStatus.Expired },
    { value: StoreSubscriptionStatus.Valid, name: StoreSubscriptionStatus.Valid },
    { value: StoreSubscriptionStatus.Paid, name: StoreSubscriptionStatus.Paid },
    { value: StoreSubscriptionStatus.NotPaid, name: StoreSubscriptionStatus.NotPaid },
  ]

  storeSubscriptions: PaginatedResult<StoreSubscriptionDto> = initialPaginatedResult

  badgeSeverity = BadgeSeverity

  subscriptions: Subscription[] = []

  constructor(private storesService: StoresService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      status: new FormControl<StoreSubscriptionStatus>(StoreSubscriptionStatus.All, { nonNullable: true }),
      pageIndex: new FormControl(1, { nonNullable: true }),
      pageCount: new FormControl(DEFAULT_PAGE_COUNT_DASHBOARD, { nonNullable: true }),
      startDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false }),
      endDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false })
    })

    this.getSubscriptions()
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  getSubscriptions() {
    const request: GetStoreSubscriptionsRequest = {
      ascending: false,
      pageCount: this.form.controls.pageCount.value,
      pageIndex: this.storeSubscriptions.pageIndex,
      searchField: StoreSubscriptionSearchField.StoreName,
      searchTerm: '',
      sortBy: StoreSubscriptionSortBy.Price,
      status: this.form.controls.status.value,
      endDate: this.form.controls.endDate.value === null ? null : toDateOnlyString(this.form.controls.endDate.value),
      startDate: this.form.controls.startDate.value === null ? null : toDateOnlyString(this.form.controls.startDate.value)
    }
    this.subscriptions.push(this.storesService.getStoreSubscriptions(request).subscribe(value => {
      this.storeSubscriptions = value
    }))
  }
}
