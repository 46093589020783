<div class="d-flex flex-row justify-content-center">
    <div class="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
        <div id="kt_app_toolbar" class="app-toolbar row mb-4">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex align-items-stretch">
                <!--begin::Toolbar wrapper-->
                <div class="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                    <!--begin::Page title-->
                    <div class="page-title d-flex flex-column justify-content-center gap-1 me-3">
                        <!--begin::Title-->
                        <h1
                            class="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                            {{ "shared.stores" | translate }}
                        </h1>
                        <!--end::Title-->
                        <app-breadcrumb activeRouteName="{{ 'shared.workHours' | translate }}"
                            [navigationRoutes]="[{ name: 'shared.home' | translate, path: '' }]"></app-breadcrumb>
                    </div>
                    <!--end::Page title-->
                </div>
                <!--end::Toolbar wrapper-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <div class="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
            <!--begin::Form-->
            <form class="form w-100" [formGroup]="form" (ngSubmit)="saveChanges($event)">
                <!--begin::Heading-->
                <div class="text-center mb-11">
                    <!--begin::Title-->
                    <h1 class="text-gray-900 fw-bolder mb-3 text-xl">
                        {{ "shared.manageWorkHours" | translate }}
                    </h1>
                    <!--end::Title-->
                </div>
                <!--begin::Heading-->

                <div formArrayName="workHours">
                    <div class="fv-row mb-4 d-flex flex-row justify-content-between align-content-stretch"
                        *ngFor="let workHour of form.controls.workHours.controls; let i = index" [formGroupName]="i">
                        <!--begin::Email-->
                        <div class="col-md-12">
                            <input type="hidden" [formControl]="workHour.controls.dayOfWeek.controls.value"
                                class="form-control bg-transparent text-center" />
                            <input type="text" [formControl]="workHour.controls.dayOfWeek.controls.name"
                                class="form-control bg-transparent text-center fs-2hx" />
                            <div class="d-flex flex-row justify-content-center align-items-center">
                                <label class="form-label fs-2hx fw-bold mx-4">{{ 'shared.from' | translate }}</label>
                                <ngb-timepicker [formControl]="workHour.controls.openAt" [meridian]="true" />
                            </div>
                            <div class="d-flex flex-row justify-content-center align-items-center">
                                <label class="form-label fs-2hx mx-4 fw-bold">{{ 'shared.to' | translate }}</label>
                                <ngb-timepicker [formControl]="workHour.controls.closeAt" [meridian]="true" />
                            </div>

                            <div class="mt-2" *ngIf="remoteErrorMessages[i]">
                                <app-error-alert [message]="remoteErrorMessages[i]"></app-error-alert>
                            </div>
                        </div>
                    </div>
                    <!--end::Input group=-->
                </div>

                <!--begin::Submit button-->

                <div class="d-flex flex-row row px-2 mt-2">
                    <button type="submit" class="btn btn-primary text-xl">
                        <!--begin::Indicator label-->
                        <span class="indicator-label">{{ "shared.save" | translate }}</span>
                        <!--end::Indicator label-->
                    </button>
                </div>
                <!--end::Submit button-->
            </form>
            <!--end::Form-->
        </div>
    </div>
</div>