<div class="d-flex flex-column flex-column-fluid mt-8">
    <div id="kt_app_toolbar" class="app-toolbar pt-6 pb-2">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex align-items-stretch">
            <!--begin::Toolbar wrapper-->
            <div class="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center gap-1 me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                        {{ 'shared.stores' | translate }}</h1>
                    <!--end::Title-->
                    <app-breadcrumb activeRouteName="{{ store?.name }}"
                        [navigationRoutes]="[{name: 'shared.storeManagement' | translate, path: null}]"></app-breadcrumb>
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar wrapper-->
        </div>
        <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-fluid">
            <!--begin::Form-->
            <form id="kt_ecommerce_add_product_form" class="form d-flex flex-column flex-lg-row"
                data-kt-redirect="apps/ecommerce/catalog/products.html">
                <!--begin::Aside column-->
                <div class="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                    <!--begin::Thumbnail settings-->
                    <div class="card card-flush py-4">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h2>{{ 'shared.logo' | translate }}</h2>
                            </div>
                            <!--end::Card title-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body text-center pt-0">
                            <!--begin::Image input-->
                            <div class="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                                data-kt-image-input="true">
                                <!--begin::Preview existing avatar-->
                                <div>
                                    <img [src]="logoName" class="image-input-wrapper w-200px h-150px object-fill" />
                                </div>
                                <!--end::Preview existing avatar-->
                                <!--begin::Label-->
                                <label
                                    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                    aria-label="Change avatar" data-bs-original-title="Change avatar"
                                    data-kt-initialized="1">
                                    <i class="ki-outline ki-pencil fs-7"></i>
                                    <!--begin::Inputs-->
                                    <input type="file" name="logo" accept="image/*" (change)="onLogoChanged($event)">
                                    <!--end::Inputs-->
                                </label>
                                <!--end::Label-->
                                <!--begin::Cancel-->
                                <span
                                    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="cancel" data-bs-toggle="tooltip"
                                    aria-label="Cancel avatar" data-bs-original-title="Cancel avatar"
                                    data-kt-initialized="1">
                                    <i class="ki-outline ki-cross fs-2"></i>
                                </span>
                                <!--end::Cancel-->
                                <!--begin::Remove-->
                                <span *ngIf="logo" (click)="resetLogo()"
                                    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow d-flex">
                                    <i class="ki-outline ki-cross fs-2"></i>
                                </span>
                                <!--end::Remove-->
                            </div>
                            <!--end::Image input-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Thumbnail settings-->

                    <!--begin::Status-->
                    <div class="card card-flush py-4">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title d-flex flex-row" *ngIf="store?.isAccepted">
                                <h2>{{ 'shared.accepted' | translate }}</h2>
                                <span class="text-muted mx-4">{{ store?.acceptedAt | date }}</span>
                            </div>
                            <div class="card-title" *ngIf="!store?.isAccepted">
                                <h2>{{ 'shared.notAccepted' | translate }}</h2>
                            </div>
                            <!--end::Card title-->
                            <!--begin::Card toolbar-->
                            <div class="card-toolbar">
                                <div class="rounded-circle w-15px h-15px"
                                    [ngClass]="{'bg-success': store?.isAccepted, 'bg-danger': !store?.isAccepted}"
                                    id="kt_ecommerce_add_product_status"></div>
                            </div>
                            <!--begin::Card toolbar-->
                        </div>
                        <!--end::Card header-->
                    </div>
                    <!--end::Status-->

                    <!--begin::Status-->
                    <div class="card card-flush py-4">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title" *ngIf="store?.isActive">
                                <h2>{{ 'shared.active' | translate }}</h2>
                            </div>
                            <div class="card-title" *ngIf="!store?.isActive">
                                <h2>{{ 'shared.deactivated' | translate }}</h2>
                            </div>
                            <!--end::Card title-->
                            <!--begin::Card toolbar-->
                            <div class="card-toolbar">
                                <div class="rounded-circle w-15px h-15px"
                                    [ngClass]="{'bg-success': store?.isActive, 'bg-danger': !store?.isActive}"
                                    id="kt_ecommerce_add_product_status"></div>
                            </div>
                            <!--begin::Card toolbar-->
                        </div>
                        <!--end::Card header-->
                    </div>
                    <!--end::Status-->
                    <!--begin::Status-->
                    <div class="card card-flush py-4">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h2>{{ 'shared.workHours' | translate }}</h2>
                            </div>
                            <!--end::Card title-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body pt-0" *ngFor="let workHour of store?.workHours">
                            <app-badge [severity]="badgeSeverity.Primary" text="{{  ('shared.' + workHour.dayOfWeek).toLowerCase() | translate }}"/>
                            <span class="text-muted mx-2">{{ workHour.openAt | time }}</span>
                            <span class="text-muted mx-8">{{ 'shared.to' | translate }}</span>
                            <span class="text-muted mx-2">{{ workHour.closeAt | time }}</span>
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Status-->
                    <!--begin::Status-->
                    <div class="card card-flush py-4">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title" *ngIf="store?.isClosed">
                                <h2>{{ 'shared.isClosed' | translate }}</h2>
                            </div>
                            <div class="card-title" *ngIf="!store?.isClosed">
                                <h2>{{ 'shared.isOpen' | translate }}</h2>
                            </div>
                            <!--end::Card title-->
                            <!--begin::Card toolbar-->
                            <div class="card-toolbar">
                                <div class="rounded-circle w-15px h-15px"
                                    [ngClass]="{'bg-success': !store?.isClosed, 'bg-danger': store?.isClosed}"
                                    id="kt_ecommerce_add_product_status"></div>
                            </div>
                            <!--begin::Card toolbar-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body pt-0">
                            <button (click)="toggleOpen()" class="btn row col-12"
                                [ngClass]="{'btn-success': store?.isClosed, 'btn-danger': !store?.isClosed}">
                                <span class="indicator-label" *ngIf="store?.isClosed">{{ 'shared.open' | translate
                                    }}</span>
                                <span class="indicator-label" *ngIf="!store?.isClosed">{{ 'shared.close' | translate
                                    }}</span>
                            </button>
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Status-->

                                        <!--begin::Status-->
                                        <div class="card card-flush py-4">
                                            <!--begin::Card header-->
                                            <div class="card-header">
                                                <div class="card-title">
                                                    <h2>{{ 'shared.cartActive' | translate }}</h2>
                                                </div>
                                                <!--begin::Card toolbar-->
                                                <div class="card-toolbar">
                                                    <div class="rounded-circle w-15px h-15px"
                                                        [ngClass]="{'bg-success': store?.cartActive, 'bg-danger': !store?.cartActive}"
                                                        id="kt_ecommerce_add_product_status"></div>
                                                </div>
                                                <!--begin::Card toolbar-->
                                            </div>
                                            <!--end::Card header-->
                                            <!--begin::Card body-->
                                            <div class="card-body pt-0">
                                                <button (click)="toggleCartActive()" class="btn row col-12"
                                                    [ngClass]="{'btn-success': !store?.cartActive, 'btn-danger': store?.cartActive}">
                                                    <span class="indicator-label" *ngIf="store?.cartActive">{{ 'shared.deactivate' | translate
                                                        }}</span>
                                                    <span class="indicator-label" *ngIf="!store?.cartActive">{{ 'shared.activate' | translate
                                                        }}</span>
                                                </button>
                                            </div>
                                            <!--end::Card body-->
                                        </div>
                                        <!--end::Status-->

                    <!--begin::Status-->
                    <div class="card card-flush py-4">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title d-flex flex-row">
                                <h2>{{ 'shared.currency' | translate }}</h2>
                                <p class="text-muted mx-4 mt-2">{{ store?.profitCurrencyCode }}</p>
                            </div>
                            <!--end::Card title-->
                        </div>
                        <!--end::Card header-->


                    </div>
                    <!--end::Status-->

                    <!--begin::Status-->
                    <div class="card card-flush py-4">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h4>{{ 'shared.currencyForSubscription' | translate }}</h4>
                                <p class="text-muted mx-4 mt-2">{{ store?.earningCurrencyCode }}</p>
                            </div>
                            <!--end::Card title-->
                        </div>
                        <!--end::Card header-->
                    </div>
                    <!--end::Status-->

                    <!--begin::Card widget 6-->
                    <div class="card card-flush">
                        <!--begin::Header-->
                        <div class="card-header pt-5">
                            <!--begin::Title-->
                            <div class="card-title d-flex flex-column">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center">
                                    <!--begin::Currency-->
                                    <span class="fs-4 fw-semibold text-gray-500 me-1 align-self-start">{{
                                        store?.profitCurrencyCode }}</span>
                                    <!--end::Currency-->
                                    <!--begin::Amount-->
                                    <span class="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{{ store?.earnings ?? 0 |
                                        shortNumber }}</span>
                                    <!--end::Amount-->
                                </div>
                                <!--end::Info-->
                                <!--begin::Subtitle-->
                                <span class="text-gray-500 pt-1 fw-semibold text-xl">{{ 'shared.profits' | translate
                                    }}</span>
                                <!--end::Subtitle-->
                            </div>
                            <!--end::Title-->
                        </div>
                        <!--end::Header-->

                    </div>
                    <!--end::Card widget 6-->

                </div>
                <!--end::Aside column-->
                <!--begin::Main column-->
                <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                    <!--begin:::Tabs-->
                    <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2"
                        role="tablist">
                        <!--begin:::Tab item-->
                        <li class="nav-item hover:cursor-pointer" role="presentation">
                            <a class="nav-link text-active-primary pb-4 hover:cursor-pointer"
                                (click)="setActiveTab('general')" [ngClass]="{'active': activeTab === 'general'}"
                                data-bs-toggle="tab" aria-selected="true" role="tab">{{
                                'storeDetails.general' | translate }}</a>
                        </li>
                        <!--end:::Tab item-->
                        <!--begin:::Tab item-->
                        <li class="nav-item hover:cursor-pointer" role="presentation">
                            <a class="nav-link text-active-primary pb-4 hover:cursor-pointer"
                                (click)="setActiveTab('subscriptions')" data-bs-toggle="tab"
                                [ngClass]="{'active': activeTab === 'subscriptions'}" aria-selected="false"
                                tabindex="-1" role="tab">{{ 'shared.subscriptions' | translate }}</a>
                        </li>
                        <!--begin:::Tab item-->
                        <li class="nav-item hover:cursor-pointer" role="presentation">
                            <a class="nav-link text-active-primary pb-4 hover:cursor-pointer"
                                (click)="setActiveTab('reviews')" data-bs-toggle="tab"
                                [ngClass]="{'active': activeTab === 'reviews'}" aria-selected="false" tabindex="-1"
                                role="tab">{{ 'shared.reviews' | translate }}</a>
                        </li>
                        <!--end:::Tab item-->
                    </ul>
                    <!--end:::Tabs-->
                    <!--begin::Tab content-->
                    <div class="tab-content">
                        <!--begin::Tab pane-->
                        <div class="tab-pane fade"
                            [ngClass]="{'show': activeTab === 'general', 'active': activeTab === 'general'}"
                            role="tab-panel">
                            <div class="d-flex flex-column gap-7 gap-lg-10">
                                <!--begin::General options-->
                                <div class="card card-flush py-4">
                                    <!--begin::Card header-->
                                    <div class="card-header d-flex flex-row justify-content-start">
                                        <div class="card-title">
                                            <h2>{{ store?.name }}</h2>
                                        </div>
                                        <div class="rating">
                                            <app-rating [rate]="store?.rate ?? 0" />
                                        </div>
                                    </div>
                                    <!--end::Card header-->
                                    <!--begin::Card body-->
                                    <div class="card-body pt-0">
                                        <!--begin::Input group-->
                                        <div class="mb-10 fv-row">
                                            <app-text fieldName="name" [formControl]="generalForm.controls.name"
                                                [remoteErrorMessage]="generalFormRemoteErrorMessages.nameRemoteErrorMessage"
                                                label="{{ 'shared.storeName' | translate }}" [required]="true" />
                                        </div>
                                        
                                        <div class="mb-10 fv-row">
                                            <app-textarea fieldName = "storeDescription" [required]="false" [formControl]="generalForm.controls.description" [remoteErrorMessage]="generalFormRemoteErrorMessages.descriptionRemoteErrorMessage" label="{{ 'shared.description' | translate }}"/>
                                        </div>
                                        <!--end::Input group-->

                                        <!--begin::Input group-->
                                        <div class="mb-10 fv-row">
                                            <app-text fieldName="address" [formControl]="generalForm.controls.address"
                                                [remoteErrorMessage]="generalFormRemoteErrorMessages.addressRemoteErrorMessage"
                                                label="{{ 'shared.address' | translate }}" />
                                        </div>
                                        <!--end::Input group-->

                                        <!--begin::Input group-->
                                        <div class="mb-10 fv-row">
                                            <app-phone-number fieldName="whatsAppNumber"
                                                [formControl]="generalForm.controls.whatsAppNumber"
                                                [dialCode]="whatsAppDial"
                                                [remoteErrorMessage]="generalFormRemoteErrorMessages.whatsAppNumberRemoteErrorMessage"
                                                label="{{ 'shared.whatsAppNumber' | translate }}" [required]="false" (countrySelected)="whatsAppDialSelected($event)" />
                                        </div>
                                        <!--end::Input group-->
                                        <div class="mb-10 fv-row">
                                            <app-store-contacts [formGroup]="generalForm"
                                                (addClicked)="addStoreContact()"
                                                [primaryContactFormControl]="generalForm.controls.primaryStoreContact"
                                                [primaryContactRemoteErrorMessage]="generalFormRemoteErrorMessages.primaryContactRemoteErrorMessage"
                                                [storeContacts]="storeContacts"
                                                [storeContactsRemoteErrorMessages]="generalFormRemoteErrorMessages.contactsRemoteErrorMessages"
                                                [primaryContact]="primaryContact"
                                                [contacts]="contacts" />
                                        </div>

                                        <div class="mb-10 fv-row">
                                            <app-delivery-zones-fields [formGroup]="generalForm"
                                                (addClicked)="addDeliveryZone()"
                                                [primaryDeliveryZoneFormControl]="generalForm.controls.primaryDeliveryZone"
                                                [primaryDeliveryZoneRemoteErrorMessage]="generalFormRemoteErrorMessages.primaryDeliveryZoneRemoteErrorMessage"
                                                [deliveryZones]="deliveryZones"
                                                [deliveryZonesRemoteErrorMessages]="generalFormRemoteErrorMessages.deliveryZonesRemoteErrorMessages" />
                                        </div>

                                        <!--begin::Input group-->
                                        <div class="mb-10 fv-row">
                                            <app-store-search [formControl]="generalForm.controls.mainStore"
                                                label="{{ 'shared.mainStore' | translate }}"
                                                [remoteErrorMessage]="generalFormRemoteErrorMessages.mainStoreRemoteErrorMessage"
                                                [isMainStores]="true" />
                                        </div>
                                        <!--end::Input group-->

                                        <div class="mb-10 fv-row">
                                            <app-countries-dropdown (countrySelected)="onCountrySelected($event)" [initialCountryId]="store?.location?.countryId!"/>
                                        </div>

                                        <div class="mb-10 fv-row">
                                            <app-cities-dropdown [formControl]="generalForm.controls.cityId" [remoteErrorMessage]="generalFormRemoteErrorMessages.cityRemoteErrorMessage" [cities]="cities" fieldName="city"/>
                                        </div>

                                        <div class="mb-10 fv-row">
                                            <app-display-language [withLabel]="true"
                                                [formControl]="generalForm.controls.displayLanguage"
                                                [remoteErrorMessge]="generalFormRemoteErrorMessages.displayLanguageRemoteErrorMessage" />
                                        </div>

                                    </div>
                                    <!--end::Card header-->
                                </div>
                                <!--end::General options-->
                                <!--begin::Media-->
                                <div class="card card-flush py-4">
                                    <!--begin::Card header-->
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h2>{{ 'shared.coverPhoto' | translate }}</h2>
                                        </div>
                                    </div>
                                    <!--end::Card header-->
                                    <!--begin::Card body-->
                                    <div class="card-body text-center pt-0">
                                        <!--begin::Image input-->
                                        <div class="image-input image-input-empty image-input-outline image-input-placeholder mb-3 w-full"
                                            data-kt-image-input="true">
                                            <!--begin::Preview existing avatar-->
                                            <div *ngIf="coverPhotoName">
                                                <img [src]="coverPhotoName"
                                                    class="image-input-wrapper w-100 h-300px object-fill" />
                                            </div>
                                            <!--end::Preview existing avatar-->
                                            <!--begin::Label-->
                                            <label
                                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                                aria-label="Change avatar" data-bs-original-title="Change avatar"
                                                data-kt-initialized="1">
                                                <i class="ki-outline ki-pencil fs-7"></i>
                                                <!--begin::Inputs-->
                                                <input type="file" name="coverPhoto" accept="image/*"
                                                    (change)="onCoverPhotoChanged($event)">

                                                <!--end::Inputs-->
                                            </label>
                                            <!--end::Label-->
                                            <!--begin::Remove-->
                                            <span *ngIf="coverPhoto" (click)="resetCoverPhoto()"
                                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow d-flex">
                                                <i class="ki-outline ki-cross fs-2"></i>
                                            </span>
                                            <!--end::Remove-->
                                        </div>
                                        <!--end::Image input-->
                                    </div>
                                    <!--end::Card body-->
                                    <!--end::Card header-->
                                </div>
                                <!--end::Media-->

                            </div>
                        </div>
                        <!--end::Tab pane-->
                        <!--begin::Tab pane-->
                        <div class="tab-pane fade"
                            [ngClass]="{'show': activeTab === 'subscriptions', 'active': activeTab === 'subscriptions'}"
                            role="tab-panel">
                            <!--begin::Products-->
                            <div class="card card-flush mt-8">
                                <!--begin::Card header-->
                                <div class="card-header py-5 gap-2 gap-md-5 mt-6">
                                    <!--begin::Card title-->
                                    <div class="row flex-row-fluid justify-content-center w-100">
                                        <div class="d-flex col-md-6">
                                            <app-date-picker label="{{ 'shared.start' | translate }}"
                                                [dateControl]="storeSubscriptionsForm.controls.startDate" />
                                        </div>
                                        <div class="d-flex col-md-6">
                                            <app-date-picker label="{{ 'shared.end' | translate }}"
                                                [dateControl]="storeSubscriptionsForm.controls.endDate" />
                                        </div>
                                        <!--end::Search-->
                                    </div>
                                    <!--begin::Card toolbar-->
                                    <div class="card-toolbar flex-row-fluid justify-content-start gap-5">
                                        <div class="w-100 mw-150px">
                                            <!--begin::Select2-->
                                            <select class="form-select form-select-solid" data-control="select2"
                                                data-hide-search="true"
                                                [formControl]="storeSubscriptionsForm.controls.status"
                                                data-placeholder="Status" data-kt-ecommerce-product-filter="status">
                                                <option *ngFor="let option of storeSubscriptionStatus"
                                                    [value]="option.value">{{
                                                    'shared.' + option.name |
                                                    translate }}</option>
                                            </select>
                                            <!--end::Select2-->
                                        </div>
                                        <div class="d-flex align-items-center" (click)="getSubscriptions()">
                                            <button class="btn btn-light btn-md">
                                                <i class="ki-outline ki-magnifier fs-3 mx-auto"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <!--end::Card toolbar-->
                                </div>
                                <!--end::Card header-->
                                <!--begin::Card body-->
                                <div class="card-body pt-0 overflow-x-scroll">
                                    <!--begin::Table-->
                                    <table class="table align-middle table-row-dashed fs-6 gy-5"
                                        id="kt_ecommerce_products_table">
                                        <thead>
                                            <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                <th class="min-w-100px">{{'shared.startAt' | translate }}</th>
                                                <th class="text-end min-w-100px">{{'shared.endAt' | translate }}
                                                </th>
                                                <th class="text-end min-w-100px">{{ 'shared.price' | translate }}</th>
                                                <th class="text-end min-w-100px"> {{ 'shared.isPaid' | translate }}
                                                </th>
                                                <th class="text-end min-w-100px"> {{ 'shared.paidAt' | translate }}
                                                </th>
                                                <th class="text-end min-w-100px"> {{ 'shared.status' | translate }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-semibold text-gray-600">
                                            <tr *ngFor="let subscription of storeSubscriptions.results let i = index">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <!--end::Thumbnail-->
                                                        <span class="fw-bold">{{ subscription.startAt | date }}</span>
                                                    </div>
                                                </td>
                                                <td class="text-end pe-0">
                                                    <span class="fw-bold">{{ subscription.endAt | date }}</span>
                                                </td>
                                                <td class="text-end pe-0" data-order="13">
                                                    <span class="fw-bold ms-3">{{ subscription.price | shortNumber
                                                        }}</span>
                                                    <span class="text-muted">{{ subscription.currencyCode }}</span>
                                                </td>
                                                <td class="text-end pe-0">
                                                    <app-badge
                                                        [severity]="subscription.isPaid ? badgeSeverity.Success : badgeSeverity.Danger"
                                                        text="{{ subscription.isPaid ? ('shared.isPaid' | translate ) : ('shared.notPaid' | translate)}}" />
                                                </td>
                                                <td class="text-end pe-0">
                                                    <span class="fw-bold ms-3">{{ subscription.paidAt | date }}</span>
                                                </td>
                                                <td class="text-end pe-0">
                                                    <app-badge
                                                        [severity]="subscription.status === 'Valid' ? badgeSeverity.Success : badgeSeverity.Danger"
                                                        text="{{ subscription.status === 'Valid' ? ('shared.valid' | translate) : ('shared.expired' | translate)}}" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!--end::Table-->

                                    <div class="row">
                                        <app-table-pagination (pageChanged)="getSubscriptions()"
                                            [pageCountControl]="storeSubscriptionsForm.controls.pageCount"
                                            [pagination]="storeSubscriptions" />
                                    </div>

                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Products-->
                        </div>
                        <!--end::Tab pane-->
                        <!--begin::Tab pane-->
                        <div class="tab-pane fade"
                            [ngClass]="{'show': activeTab === 'reviews', 'active': activeTab === 'reviews'}"
                            id="kt_ecommerce_add_product_reviews" role="tab-panel">
                            <div class="d-flex flex-column gap-7 gap-lg-10">
                                <!--begin::Reviews-->
                                <div class="card card-flush py-4">
                                    <!--begin::Card header-->
                                    <div class="card-header">
                                        <!--begin::Card title-->
                                        <div class="card-title w-100">
                                            <h2> {{ 'shared.reviews' | translate }}</h2>
                                        </div>
                                        <!--end::Card title-->

                                        <!--begin::Card title-->
                                        <div class="row flex-row-fluid justify-content-center my-4 w-100">
                                            <div class="d-flex col-md-6">
                                                <app-date-picker label="{{ 'shared.start' | translate }}"
                                                    [dateControl]="storeReviewsForm.controls.startDate" />
                                            </div>
                                            <div class="d-flex col-md-6">
                                                <app-date-picker label="{{ 'shared.end' | translate }}"
                                                    [dateControl]="storeReviewsForm.controls.endDate" />
                                            </div>
                                            <!--end::Search-->
                                        </div>

                                        <!--begin::Card toolbar-->
                                        <div class="card-toolbar flex-row-fluid justify-content-start gap-5">
                                            <div class="w-100 mw-150px">
                                                <!--begin::Select2-->
                                                <select class="form-select form-select-solid" data-control="select2"
                                                    data-hide-search="true"
                                                    [formControl]="storeReviewsForm.controls.isApproved"
                                                    data-placeholder="Status" data-kt-ecommerce-product-filter="status">
                                                    <option *ngFor="let option of storeReviewsStatus"
                                                        [value]="option.value">{{
                                                        'shared.' + option.name |
                                                        translate }}</option>
                                                </select>
                                                <!--end::Select2-->
                                            </div>
                                            <div class="d-flex align-items-center" (click)="getStoreReviews()">
                                                <button class="btn btn-light btn-md">
                                                    <i class="ki-outline ki-magnifier fs-3 mx-auto"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <!--begin::Card toolbar-->
                                        <div class="w-100 card-toolbar flex-row-fluid justify-content-end">
                                            <app-rating [rate]="store?.rate ?? 0" />
                                        </div>
                                        <!--end::Card toolbar-->

                                    </div>
                                    <!--end::Card header-->
                                    <!--begin::Card body-->
                                    <div class="card-body pt-0">
                                        <!--begin::Table-->
                                        <table class="table table-row-dashed fs-6 gy-5 my-0"
                                            id="kt_ecommerce_add_product_reviews">
                                            <thead>
                                                <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                    <th class="min-w-100px">{{ 'shared.rating' | translate }}</th>
                                                    <th class="min-w-100px">{{ 'shared.customer' | translate }}</th>
                                                    <th class="min-w-100px">{{ 'shared.review' | translate }}</th>
                                                    <th class="min-w-100px text-end fs-7">{{ 'shared.createdAt' |
                                                        translate }}</th>
                                                    <th class="min-w-100px">{{ 'shared.accepted' | translate }}</th>
                                                    <th class="min-w-100px text-end fs-7">{{ 'shared.acceptedAt' |
                                                        translate }}</th>
                                                    <th class="min-w-100px text-end fs-7"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let review of storeReviews.results; let i = index">
                                                    <td data-order="rating-5">
                                                        <app-rating [rate]="review.rate" />
                                                    </td>
                                                    <td>
                                                        <app-name-initial-avatar [name]="review.userName" />
                                                    </td>
                                                    <td class="text-gray-600 fw-bold">{{ review.review }}</td>
                                                    <td class="text-end">
                                                        <span class="fw-semibold text-muted">{{ review.createdAt | date
                                                            }}</span>
                                                    </td>
                                                    <td class="text-end">
                                                        <app-badge
                                                            [severity]="review.isApproved ? badgeSeverity.Success : badgeSeverity.Danger"
                                                            text="{{ review.isApproved ? ('shared.accepted' | translate) : ('shared.notAccepted' | translate)}}" />
                                                    </td>
                                                    <td class="text-end">
                                                        <span class="fw-semibold text-muted">{{ review.approvedAt | date
                                                            }}</span>
                                                    </td>
                                                    <td class="text-end">
                                                        <a (click)="toggleReviewAction(i)"
                                                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary hover:cursor-pointer"
                                                            data-kt-menu-trigger="click"
                                                            data-kt-menu-placement="bottom-end">
                                                            <i class="ki-outline ki-down fs-5 ms-1"></i>
                                                        </a>
                                                        <!--begin::Menu-->
                                                        <div [ngClass]="{ show: reviewsActionsToggles[i] }"
                                                            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                                                            data-kt-menu="true">
                                                            <!--begin::Menu item-->

                                                            <div class="menu-item px-3" *ngIf="!review.isApproved"
                                                                (click)="approveReview(i)">
                                                                <a class="menu-link px-3 text-lg">
                                                                    {{ "shared.approve" | translate }}
                                                                </a>
                                                            </div>
                                                            <div class="menu-item px-3" (click)="deleteReview(i)">
                                                                <a class="menu-link px-3 text-lg">
                                                                    {{ "shared.delete" | translate }}
                                                                </a>
                                                            </div>
                                                            <!--end::Menu item-->
                                                        </div>
                                                    </td>
                                                    <!--end::Menu-->
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!--end::Table-->

                                        <div class="row">
                                            <app-table-pagination
                                                [pageCountControl]="storeReviewsForm.controls.pageCount"
                                                [pagination]="storeReviews" (pageChanged)="getStoreReviews()" />
                                        </div>

                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Reviews-->
                            </div>
                        </div>
                        <!--end::Tab pane-->
                    </div>
                    <!--end::Tab content-->
                    <div class="d-flex justify-content-end" *ngIf="activeTab === 'general'">
                        <!--begin::Button-->
                        <button (click)="reset()" class="btn btn-light me-5">{{ 'shared.cancel' | translate }}</button>
                        <!--end::Button-->
                        <!--begin::Button-->
                        <button (click)="submit()" class="btn btn-primary">
                            <span class="indicator-label">{{ 'shared.saveChanges' | translate }}</span>
                        </button>
                        <!--end::Button-->
                    </div>
                </div>
                <!--end::Main column-->
            </form>
            <!--end::Form-->
        </div>
        <!--end::Content container-->
    </div>
    <!--end::Content-->
</div>