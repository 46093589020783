import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { AuthenticationLayoutComponent } from './authentication/components/authentication-layout/authentication-layout.component';
import { HomeComponent } from './foodenza/components/home/home.component';
import { storeOwnerGuard } from './guards/store-owner.guard';
import { StoreDetailsComponent } from './components/store-details/store-details.component';
import { SubscriptionsListComponent } from './components/subscriptions-list/subscriptions-list.component';
import { ReviewsListComponent } from './components/reviews-list/reviews-list.component';
import { ManageWorkHoursComponent } from './components/manage-work-hours/manage-work-hours.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (res) => res.AuthenticationModule
      ),
    component: AuthenticationLayoutComponent,
  },
  {
    path: 'dashboard',
    component: IndexComponent,
    children: [
      { path: '', pathMatch: 'full', component: StatisticsComponent },
      {
        path: 'categories',
        loadChildren: () =>
          import('./categories/categories.module').then(
            (res) => res.CategoriesModule
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./products/products.module').then(
            (res) => res.ProductsModule
          ),
      },
      {
        path: 'store', component: StoreDetailsComponent
      },
      {
        path: 'subscriptions', component: SubscriptionsListComponent
      },
      {
        path: 'reviews', component: ReviewsListComponent
      },
      {
        path: 'work-hours', component: ManageWorkHoursComponent
      },
      {
        path: 'voucher-codes', loadChildren: () => import('./voucher-codes/voucher-codes.module').then(res => res.VoucherCodesModule)
      },
      {
        path: 'orders', loadChildren: () => import('./orders/orders.module').then(res => res.OrdersModule)
      },
      {
        path: 'profile', loadChildren: () => import('./profile/profile.module').then(res => res.ProfileModule)
      }
    ],
    canActivate: [storeOwnerGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./foodenza/foodenza.module').then((res) => res.FoodenzaModule),
    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
