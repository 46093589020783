<div class="d-flex flex-column flex-root app-root" id="kt_app_root">
    <!--begin::Page-->
    <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
        <!--begin::Header-->
        <div id="kt_app_header" class="app-header d-flex flex-column flex-stack">
            <app-index-layout></app-index-layout>
        </div>
        <div class="app-wrapper flex-column flex-row-fluid overflow-scroll">
            <div class="app-main flex-column flex-row-fluid pt-20" id="kt_app_main">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>