import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BadgeSeverity, Language, ToastSeverity } from '../../shared/models/enums';
import { storeDisplayLanguages, StoreDetails, GeneralStoreForm, PaginatedResult, StoreSubscriptionDto, initialPaginatedResult, StoreDetailsSubscriptionsFilterForm, StoreReviewDto, GetStoreReviewsForm, StoreSubscriptionStatus, GeneralStore, GetStoreSubscriptionsRequest, StoreSubscriptionSearchField, StoreSubscriptionSortBy, UpdateStoreRequest, StoreContactType, MessageResponse, GetStoreReviewsRequest, StoreContactDto, DEFAULT_PAGE_COUNT_DASHBOARD, City, Country } from '../../shared/models/models';
import { StoresService } from '../../shared/services/stores.service';
import { ToastService } from '../../shared/services/toast.service';
import { toDateOnlyString } from '../../utilities/date-utilities';
import { DeleteWarningComponent } from '../../shared/components/delete-warning/delete-warning.component';
import { DeliveryZone } from '../../foodenza/models/models';
import { CountriesService } from '../../shared/services/countries.service';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrl: './store-details.component.css'
})
export class StoreDetailsComponent {

  subscriptions: Subscription[] = [];

  languages = storeDisplayLanguages;

  primaryContact: { id: number | null, value: string | null } = {
    id: null,
    value: null
  }

  contacts: { id: number | null, value: string | null }[] = []

  selectedCountry?: Country | null

  store?: StoreDetails;

  generalForm!: FormGroup<GeneralStoreForm>;

  generalFormRemoteErrorMessages: {
    displayLanguageRemoteErrorMessage?: string;
    nameRemoteErrorMessage?: string;
    addressRemoteErrorMessage?: string;
    whatsAppNumberRemoteErrorMessage?: string;
    primaryContactRemoteErrorMessage?: string;
    contactsRemoteErrorMessages: string[];
    logoRemoteErrorMessage?: string;
    coverPhotoRemoteErrorMessage?: string;
    mainStoreRemoteErrorMessage?: string;
    primaryDeliveryZoneRemoteErrorMessage?: string;
    descriptionRemoteErrorMessage?: string;
    cityRemoteErrorMessage?: string;
    deliveryZonesRemoteErrorMessages: string[];
  } = {
      contactsRemoteErrorMessages: [],
      deliveryZonesRemoteErrorMessages: []
    };

  logoName: File | null | string = null;

  coverPhotoName: File | null | string = null;

  logo: File | null = null;

  coverPhoto: File | null = null;

  activeTab: 'general' | 'subscriptions' | 'reviews' = 'general'

  cities: City[] = []

  storeSubscriptions: PaginatedResult<StoreSubscriptionDto> = initialPaginatedResult

  storeSubscriptionsForm!: FormGroup<StoreDetailsSubscriptionsFilterForm>

  subscriptionsPages: { index: number }[] = []

  storeReviews: PaginatedResult<StoreReviewDto> = initialPaginatedResult

  storeReviewsForm!: FormGroup<GetStoreReviewsForm>

  whatsAppDial: string | null = null

  storeSubscriptionStatus: { value: StoreSubscriptionStatus, name: string }[] = [
    { value: StoreSubscriptionStatus.All, name: StoreSubscriptionStatus.All },
    { value: StoreSubscriptionStatus.Expired, name: StoreSubscriptionStatus.Expired },
    { value: StoreSubscriptionStatus.Valid, name: StoreSubscriptionStatus.Valid },
    { value: StoreSubscriptionStatus.Paid, name: StoreSubscriptionStatus.Paid },
    { value: StoreSubscriptionStatus.NotPaid, name: StoreSubscriptionStatus.NotPaid },
  ]

  storeReviewsStatus: { value: boolean | null, name: string }[] = [
    { value: null, name: "all" },
    { value: true, name: "accepted" },
    { value: false, name: "notAccepted" }
  ]

  badgeSeverity = BadgeSeverity

  reviewsActionsToggles: boolean[] = []

  constructor(
    private storesService: StoresService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private modalSerivce: NgbModal,
    private countriesService: CountriesService
  ) {

  }

  get storeContacts() {
    return this.generalForm.get('storeContacts') as FormArray;
  }

  get deliveryZones() {
    return this.generalForm.controls.deliveryZones as FormArray;
  }

  removeContact(index: number) {
    this.storeContacts.removeAt(index);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  initalizeForm() {
    this.generalForm = this.formBuilder.group({
      address: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      displayLanguage: new FormControl(Language.AR, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      name: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      }),
      whatsAppNumber: new FormControl<string | null>(null),
      mainStore: new FormControl<GeneralStore | null>(null),
      storeContacts: this.formBuilder.array([]),
      primaryStoreContact: this.formBuilder.group({
        id: new FormControl(0, { nonNullable: true }),
        value: new FormControl<string | null>(null),
      }),
      primaryDeliveryZone: this.formBuilder.group({
        id: new FormControl<number | null>(null, { nonNullable: true }),
        zone: new FormControl<string | null>(null),
        fees: new FormControl<number | null>(null)
      }),
      deliveryZones: this.formBuilder.array([]),
      description: new FormControl<string | null>(null),
      cityId: new FormControl<number | null>(null)
    });
  }

  patchStoreContacts(values: StoreContactDto[]): void {
    if (values.length <= 1) {
      return
    }

    const contacts: { id: number | null, value: string | null }[] = []

    values.forEach((value, index) => {
      if (index === 0) {
        return
      }
      contacts.push({
        id: value.id,
        value: value.value
      })
    })

    this.contacts = contacts
  }

  patchDeliveryZones(values: DeliveryZone[]): void {
    if (values.length <= 1) {
      return
    }

    const formArray = this.deliveryZones;

    formArray.clear();

    values.filter((el, index) => index !== 0).forEach((el) => {
      formArray.push(
        this.formBuilder.group({
          id: new FormControl(el.id),
          fees: new FormControl(el.fees),
          zone: new FormControl(el.zone)
        })
      );
    });
  }

  getStoreDetails() {
    this.subscriptions.push(
      this.storesService
        .getStoreDetails()
        .subscribe((store) => {
          this.store = store;

          this.logoName = store.logo;

          this.coverPhotoName = store.coverPhoto;

          const whatsAppSplit = store.whatsAppNumber.split(' ')

          const whatsAppDial = whatsAppSplit[0]

          const whatsApp = whatsAppSplit[1]

          if (whatsAppDial && whatsApp) {
            this.whatsAppDial = whatsAppDial
          }

          this.generalForm.patchValue({
            address: store.address,
            displayLanguage: store.displayLanguage,
            name: store.name,
            whatsAppNumber: whatsApp,
            mainStore: store.mainStore,
            description: store.description,
            cityId: store.location?.cityId
          });

          if (store.storeContacts[0] !== undefined) {
            this.primaryContact = {
              id: store.storeContacts[0].id,
              value: store.storeContacts[0].value
            }
          }

          if (store.deliveryZones[0] !== undefined) {
            this.generalForm.patchValue({
              primaryDeliveryZone: store.deliveryZones[0]
            })
          }

          this.patchStoreContacts(store.storeContacts);

          this.patchDeliveryZones(store.deliveryZones)
        })
    );
  }

  ngOnInit(): void {
    this.initalizeForm();

    this.storeSubscriptionsForm = this.formBuilder.group({
      status: new FormControl<StoreSubscriptionStatus>(StoreSubscriptionStatus.All, { nonNullable: true }),
      pageIndex: new FormControl(1, { nonNullable: true }),
      pageCount: new FormControl(DEFAULT_PAGE_COUNT_DASHBOARD, { nonNullable: true }),
      startDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false }),
      endDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false })
    })

    this.subscriptions.push(this.storeSubscriptionsForm.controls.pageCount.valueChanges.subscribe(value => {
      this.getSubscriptions()
    }))

    this.storeReviewsForm = this.formBuilder.group({
      endDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false }),
      startDate: new FormControl<NgbDateStruct | null>(null, { nonNullable: false }),
      isApproved: new FormControl<boolean | null>(null, { nonNullable: false }),
      pageCount: new FormControl<number>(DEFAULT_PAGE_COUNT_DASHBOARD, { nonNullable: true })
    })

    this.subscriptions.push(this.storeReviewsForm.controls.pageCount.valueChanges.subscribe(value => {
      this.getStoreReviews()
    }))

    this.getStoreDetails()
  }

  createGetSubscriptionsRequest() {
    const request: GetStoreSubscriptionsRequest = {
      ascending: false,
      pageCount: this.storeSubscriptionsForm.controls.pageCount.value,
      pageIndex: this.storeSubscriptions.pageIndex,
      searchField: StoreSubscriptionSearchField.StoreName,
      searchTerm: '',
      sortBy: StoreSubscriptionSortBy.Price,
      status: this.storeSubscriptionsForm.controls.status.value,
      endDate: this.storeSubscriptionsForm.controls.endDate.value === null ? null : toDateOnlyString(this.storeSubscriptionsForm.controls.endDate.value),
      startDate: this.storeSubscriptionsForm.controls.startDate.value === null ? null : toDateOnlyString(this.storeSubscriptionsForm.controls.startDate.value)
    }

    return request
  }

  reset() {
    this.ngOnInit();
  }

  submit() {
    if (!this.generalForm.valid) {
      this.generalForm.markAllAsTouched()
      return;
    }

    const storeContacts: { id: number, value: string, type: StoreContactType }[] = []

    if (this.primaryContact.value) {
      storeContacts.push({
        id: this.primaryContact.id ?? 0,
        value: this.primaryContact.value ?? '',
        type: StoreContactType.phoneNumber
      })
    }

    if (this.contacts.length !== 0) {
      const contacts = this.contacts.filter(control => control.value).map((control) => {
        return {
          id: control.id ?? 0,
          type: StoreContactType.phoneNumber,
          value: control.value ?? '',
        };
      })

      contacts.forEach(contact => storeContacts.push(contact))
    }

    const deliveryZones: { id: number, zone: string, fees: number }[] = []

    if (this.generalForm.controls.primaryDeliveryZone.value.zone) {
      deliveryZones.push({
        id: this.generalForm.controls.primaryDeliveryZone.value.id ?? 0,
        zone: this.generalForm.controls.primaryDeliveryZone.value.zone ?? '',
        fees: this.generalForm.controls.primaryDeliveryZone.value.fees ?? 0
      })
    }

    if (this.generalForm.controls.deliveryZones.controls.length != 0) {
      const zones = this.generalForm.controls.deliveryZones.controls.map(control => {
        return {
          id: control.get('id')?.value ?? 0,
          fees: control.get('fees')?.value ?? 0,
          zone: control.get('zone')?.value ?? ''
        }
      })

      zones.forEach(zone => deliveryZones.push(zone))
    }

    const whatsApp = this.whatsAppDial && this.generalForm.controls.whatsAppNumber.value ? this.whatsAppDial + ' ' + this.generalForm.controls.whatsAppNumber.value : null

    const request: UpdateStoreRequest = {
      address: this.generalForm.controls.address.value,
      displayLanguage: this.generalForm.controls.displayLanguage.value,
      mainStoreId: this.generalForm.controls.mainStore.value?.id ?? null,
      name: this.generalForm.controls.name.value,
      storeContacts: storeContacts,
      whatsAppNumber: whatsApp,
      logo: this.logo,
      coverPhoto: this.coverPhoto,
      deliveryZones: deliveryZones,
      description: this.generalForm.controls.description.value ?? '',
      cityId: this.generalForm.controls.cityId.value
    };

    this.subscriptions.push(
      this.storesService.updateStore(request).subscribe((response) => {
        if ('validationMap' in response) {
          const contactsRemoteErrorMessages: string[] = []

          const deliveryZonesRemoteErrorMessages: string[] = []

          this.generalFormRemoteErrorMessages = {
            coverPhotoRemoteErrorMessage: response.validationMap['CoverPhoto'],
            addressRemoteErrorMessage: response.validationMap['Address'],
            displayLanguageRemoteErrorMessage:
              response.validationMap['DisplayLanguage'],
            logoRemoteErrorMessage: response.validationMap['Logo'],
            mainStoreRemoteErrorMessage: response.validationMap['MainStoreId'],
            nameRemoteErrorMessage: response.validationMap['Name'],
            primaryContactRemoteErrorMessage:
              response.validationMap['StoreContacts'] ??
              response.validationMap['StoreContacts[0].Value'],
            whatsAppNumberRemoteErrorMessage:
              response.validationMap['WhatsAppNumber'],
            contactsRemoteErrorMessages: [].constructor(
              this.generalForm?.controls.storeContacts.controls.length
            )
              .forEach((_: any, index: number) => {
                const errorMessage =
                  response.validationMap[`StoreContacts[${index}].Value`];

                if (!errorMessage) {
                  return;
                }

                contactsRemoteErrorMessages.push(errorMessage)
              }),
            deliveryZonesRemoteErrorMessages: [].constructor(this.generalForm?.controls.deliveryZones.controls.length)
              .forEach((_: any, index: number) => {
                const errorMessage = response.validationMap[`DeliveryZones[${index}].Zone`] ?? response.validationMap[`DeliveryZones[${index}.Fees]`]

                if (!errorMessage) {
                  return
                }

                deliveryZonesRemoteErrorMessages.push(errorMessage)
              })
          };

          this.generalFormRemoteErrorMessages.contactsRemoteErrorMessages = contactsRemoteErrorMessages

          this.generalFormRemoteErrorMessages.descriptionRemoteErrorMessage = response.validationMap['Description']

          this.generalFormRemoteErrorMessages.cityRemoteErrorMessage = response.validationMap['CityId']

          return;
        }

        this.toastService.addMessage({
          message: response.message,
          severity: ToastSeverity.Success,
        })

        this.getStoreDetails()
      })
    );
  }

  addStoreContact() {
    const formGroup = this.formBuilder.group({
      id: new FormControl<number | null>(null),
      value: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });

    this.generalForm?.controls.storeContacts.push(formGroup);

    this.subscriptions.push(
      formGroup.controls.value.valueChanges.subscribe((_) => {
        const index =
          this.generalForm?.controls.storeContacts.controls.length ?? 0 - 1;

        if (
          index >
          this.generalFormRemoteErrorMessages.contactsRemoteErrorMessages
            .length -
          1
        ) {
          return;
        }

        this.generalFormRemoteErrorMessages.contactsRemoteErrorMessages =
          this.generalFormRemoteErrorMessages.contactsRemoteErrorMessages.filter(
            (value, i) => index !== i
          );
      })
    );
  }

  addDeliveryZone() {
    const formGroup = this.formBuilder.group({
      id: new FormControl<number>(0),
      zone: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      fees: new FormControl(0, { nonNullable: true, validators: [Validators.required] })
    });

    this.generalForm?.controls.deliveryZones.push(formGroup);

    this.subscriptions.push(
      formGroup.controls.zone.valueChanges.subscribe((_) => {
        const index =
          this.generalForm?.controls.deliveryZones.controls.length ?? 0 - 1;

        if (
          index >
          this.generalFormRemoteErrorMessages.deliveryZonesRemoteErrorMessages
            .length -
          1
        ) {
          return;
        }

        this.generalFormRemoteErrorMessages.deliveryZonesRemoteErrorMessages =
          this.generalFormRemoteErrorMessages.contactsRemoteErrorMessages.filter(
            (value, i) => index !== i
          );
      })
    );
  }

  onLogoChanged(event: Event) {
    this.generalFormRemoteErrorMessages.logoRemoteErrorMessage = undefined;

    if (
      (event.target as HTMLInputElement).files &&
      (event.target as HTMLInputElement).files?.length
    ) {
      const target = event.target as HTMLInputElement;

      const file = target.files?.item(0);

      const fileReader = new FileReader();

      fileReader.onload = (event: any) => {
        this.logoName = event.target.result;
      };

      fileReader.readAsDataURL(file as Blob);

      this.logo = file as File;
    }
  }

  onCoverPhotoChanged(event: Event) {
    this.generalFormRemoteErrorMessages.coverPhotoRemoteErrorMessage =
      undefined;

    if (
      (event.target as HTMLInputElement).files &&
      (event.target as HTMLInputElement).files?.length
    ) {
      const target = event.target as HTMLInputElement;

      const file = target.files?.item(0);

      const fileReader = new FileReader();

      fileReader.onload = (event: any) => {
        this.coverPhotoName = event.target.result;
      };

      fileReader.readAsDataURL(file as Blob);

      this.coverPhoto = file as File;
    }
  }

  resetLogo() {
    this.logoName = this.store!.logo;

    this.logo = null;
  }

  resetCoverPhoto() {
    this.coverPhotoName = this.store!.coverPhoto;

    this.coverPhoto = null;
  }

  setActiveTab(activeTab: 'reviews' | 'general' | 'subscriptions') {
    this.activeTab = activeTab

    if (activeTab === 'subscriptions') {
      this.getSubscriptions()

      return
    }

    if (activeTab === 'reviews') {
      this.getStoreReviews()

      return
    }
  }

  getSubscriptions() {
    const request = this.createGetSubscriptionsRequest()

    this.subscriptions.push(this.storesService.getStoreSubscriptions(request).subscribe(value => {
      this.storeSubscriptions = value
    }))
  }

  createStoreReviewsRequest() {
    const request: GetStoreReviewsRequest = {
      ascending: false,
      endDate: this.storeReviewsForm.controls.endDate.value === null ? null : toDateOnlyString(this.storeReviewsForm.controls.endDate.value),
      startDate: this.storeReviewsForm.controls.startDate.value === null ? null : toDateOnlyString(this.storeReviewsForm.controls.startDate.value),
      isApproved: this.storeReviewsForm.controls.isApproved.value,
      pageCount: this.storeReviewsForm.controls.pageCount.value,
      pageIndex: this.storeReviews.pageIndex
    }

    return request
  }

  getStoreReviews() {
    const request = this.createStoreReviewsRequest()

    this.subscriptions.push(this.storesService.getStoreReviews(request).subscribe(value => {
      this.storeReviews = value

      const actionsToggles = []

      for (let i = 0; i < value.results.length; i++) {
        actionsToggles.push(false)
      }

      this.reviewsActionsToggles = actionsToggles
    }))
  }

  toggleOpen() {
    this.subscriptions.push(this.storesService.toggleOpen().subscribe(response => {
      this.toastService.addMessage({ message: response.message, severity: ToastSeverity.Success })

      this.store!.isClosed = !this.store!.isClosed
    }))
  }

  approveReview(index: number) {
    const review = this.storeReviews.results[index]

    this.subscriptions.push(this.storesService.approveReview(review.id).subscribe(response => {
      this.getStoreReviews()

      this.toastService.addMessage({ message: response.message, severity: ToastSeverity.Success })
    }))
  }

  deleteReview(index: number) {
    const review = this.storeReviews.results[index]

    const modalRef = this.modalSerivce.open(DeleteWarningComponent)

    modalRef.componentInstance.confirmed.subscribe(() => {
      this.subscriptions.push(this.storesService.deleteReview(review.id).subscribe(response => {
        this.getStoreReviews()

        this.toastService.addMessage({ message: response.message, severity: ToastSeverity.Success })

        modalRef.close()
      }))
    })
  }

  toggleReviewAction(index: number) {
    this.reviewsActionsToggles = this.reviewsActionsToggles.map((value, valueIndex) => {
      if (valueIndex === index) {
        return !value
      }

      return value
    })
  }

  toggleCartActive() {
    this.subscriptions.push(this.storesService.toggleCartActive().subscribe(response => {
      this.toastService.addMessage({ message: response.message, severity: ToastSeverity.Success })

      this.store!.cartActive = !this.store!.cartActive
    }))
  }

  onCountrySelected(country: Country | null) {
    this.selectedCountry = country

    if (country) {
      this.subscriptions.push(this.countriesService.getCountryCities(country.id).subscribe(value => {
        this.cities = value
      }))

      return
    }

    this.generalForm.controls.cityId.setValue(null)

    this.cities = []
  }

  whatsAppDialSelected(country: Country | null) {
    this.whatsAppDial = country?.dialCode ?? null
  }
}
