<!--begin::Header main-->
<div class="d-flex flex-stack flex-grow-1 py-2 px-1">
  <div
    class="app-header-logo d-flex align-items-center ps-lg-12"
    id="kt_app_header_logo"
  >
    <!--begin::Sidebar toggle-->
    <div
      id="kt_app_sidebar_toggle"
      (click)="sidebarToggle()"
      [ngClass]="{ active: sideBarOpen }"
      class="app-sidebar-toggle btn btn-sm btn-icon bg-body btn-color-gray-500 btn-active-color-primary w-30px h-30px ms-n2 me-4 d-none d-lg-flex"
      data-kt-toggle="true"
      data-kt-toggle-state="active"
      data-kt-toggle-target="body"
      data-kt-toggle-name="app-sidebar-minimize"
    >
      <i class="ki-outline ki-abstract-14 fs-3 mt-1"></i>
    </div>
    <!--end::Sidebar toggle-->
    <!--begin::Sidebar mobile toggle-->
    <div
      class="btn btn-icon btn-active-color-primary w-35px h-35px ms-3 me-2 d-flex d-lg-none my-2"
      id="kt_app_sidebar_mobile_toggle"
      (click)="sidebarMobileToggle()"
      [ngClass]="{ active: mobileSideBarOpen }"
    >
      <i class="ki-outline ki-abstract-14 fs-2"></i>
    </div>
    <!--end::Sidebar mobile toggle-->
    <!--begin::Logo-->
    <a class="app-sidebar-logo mx-2 my-2 hidden md:block" [routerLink]="''">
      <img
        alt="Logo"
        src="assets/media/foodenza/logo-3.png"
        class="h-75px theme-light-show object-cover"
      />
      <img
        alt="Logo"
        src="assets/media/foodenza/logo-1.png"
        class="h-75px theme-dark-show object-cover"
      />
    </a>
    <!--end::Logo-->
  </div>
  <!--begin::Navbar-->
  <div
    class="app-navbar flex-grow-1 justify-content-end"
    id="kt_app_header_navbar"
  >
    <div class="app-navbar flex-grow-1 justify-content-end mx-10">
      <div class="app-navbar-item mx-10">
        <app-notification-dropdown></app-notification-dropdown>
        <div
          class="w-35px h-35px"
          style="
            display: flex;
            flex-direction: row;
            padding-top: 8px !important;
          "
        >
          <app-language-dropdown></app-language-dropdown>
        </div>
      </div>
    </div>

    <!--begin::Notifications-->
    <div class="app-navbar-item ms-2 ms-lg-6">
      <!--begin::Menu- wrapper-->
      <div
        class="btn btn-icon btn-custom btn-color-gray-600 btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        (click)="toggleTheme()"
      >
        <img
          src="assets/media/svg/misc/dark-theme-toggle.svg"
          class="w-10 h-10"
        />
      </div>
      <!--end::Menu wrapper-->
    </div>
    <!--end::Notifications-->
    <!--begin::Action-->
    <div class="app-navbar-item ms-2 ms-lg-6 me-lg-6" (click)="logout()">
      <!--begin::Link-->
      <a
        class="btn btn-icon btn-custom btn-color-gray-600 btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
      >
        <i class="ki-outline ki-exit-right fs-1"></i>
      </a>
      <!--end::Link-->
    </div>
    <!--end::Action-->
  </div>
  <!--end::Navbar-->
</div>
<!--end::Header main-->

<div
  class="overlay"
  *ngIf="mobileSideBarOpen"
  (click)="sidebarMobileToggle()"
></div>

<div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
  <!--begin::Sidebar-->
  <div
    id="kt_app_sidebar"
    class="app-sidebar flex-column pt-12"
    [ngClass]="{
      drawer: mobileSideBarOpen,
      'drawer-on': mobileSideBarOpen,
      'drawer-start': mobileSideBarOpen
    }"
    data-kt-drawer="true"
    data-kt-drawer-name="app-sidebar"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="250px"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
  >
    <!--begin::Wrapper-->
    <div class="app-sidebar-wrapper">
      <div
        id="kt_app_sidebar_wrapper"
        class="hover-scroll-y my-5 my-lg-2 mx-4"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_header"
        data-kt-scroll-wrappers="#kt_app_sidebar_wrapper"
        data-kt-scroll-offset="5px"
      >
        <!--begin::Sidebar menu-->
        <div
          id="#kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
          class="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary px-3 mb-5"
        >
          <div data-kt-menu-trigger="click" class="menu-item">
            <!--begin:Menu link-->
            <a
              [routerLink]="'/dashboard'"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="menu-link">
                <span class="menu-icon h5">
                  <i ngClass="ki-outline ki-home-2 fs-2"></i>
                </span>
                <span class="menu-title h5">{{
                  "shared.home" | translate
                }}</span>
              </span>
              <!--end:Menu link-->
            </a>
          </div>

          <app-sidebar-menu-accordion
            title="{{ 'shared.storeManagement' | translate }}"
            iconClass="ki-outline ki-gift fs-2"
            [menuItems]="storeManagementAccordionItems"
            [isOpen]="false"
          ></app-sidebar-menu-accordion>
          <app-sidebar-menu-accordion
            title="{{ 'shared.profileManagement' | translate }}"
            iconClass="ki-outline ki-gear fs-2"
            [menuItems]="profileManagementAccordionItems"
            [isOpen]="false"
          ></app-sidebar-menu-accordion>

          <div data-kt-menu-trigger="click" class="menu-item">
            <!--begin:Menu link-->
            <a routerLink="{{ '/storee/' + storeSlug }}" target="_blank">
              <span class="menu-link">
                <span class="menu-icon h5">
                  <i ngClass="ki-outline ki-home-2 fs-2"></i>
                </span>
                <span class="menu-title h5">{{
                  "shared.foodenza" | translate
                }}</span>
              </span>
              <!--end:Menu link-->
            </a>
          </div>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Sidebar-->
    </div>
  </div>
</div>
