import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DayOfWeek, Language, ToastSeverity } from '../../shared/models/enums';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../../shared/services/settings.service';
import { StoresService } from '../../shared/services/stores.service';
import { Subscription } from 'rxjs';
import { CollectionWrapper } from '../../shared/models/models';
import { WorkHour } from '../../models/models';
import { parseTimeString, parseTimeStructToIsoDate } from '../../utilities/date-utilities';
import { ToastService } from '../../shared/services/toast.service';

@Component({
  selector: 'app-manage-work-hours',
  templateUrl: './manage-work-hours.component.html',
  styleUrl: './manage-work-hours.component.css'
})
export class ManageWorkHoursComponent implements OnInit, OnDestroy {
  form: FormGroup<{
    workHours: FormArray<FormGroup<{
      dayOfWeek: FormGroup<{ value: FormControl<DayOfWeek>, name: FormControl<string> }>,
      openAt: FormControl<NgbTimeStruct>,
      closeAt: FormControl<NgbTimeStruct>
    }>>
  }>

  remoteErrorMessages: string[] = []

  arabicDaysOfWeekMap: { [key in DayOfWeek]: string } = {
    [DayOfWeek.Sunday]: 'الاحد',
    [DayOfWeek.Monday]: 'الاثنين',
    [DayOfWeek.Tuesday]: 'الثلاثاء',
    [DayOfWeek.Wednesday]: 'الاربعاء',
    [DayOfWeek.Thursday]: 'الخميس',
    [DayOfWeek.Friday]: 'الجمعة',
    [DayOfWeek.Saturday]: 'السبت',
  }

  subscriptions: Subscription[] = []

  workHours: CollectionWrapper<WorkHour> = {
    results: []
  }

  constructor(private formBuilder: FormBuilder, private settingsService: SettingsService, private storesService: StoresService, private toastService: ToastService) {
    this.form = this.formBuilder.group({
      workHours: this.formBuilder.array<FormGroup<{
        dayOfWeek: FormGroup<{ value: FormControl<DayOfWeek>, name: FormControl<string> }>,
        openAt: FormControl<NgbTimeStruct>,
        closeAt: FormControl<NgbTimeStruct>
      }>>(this.defaultWorkHoursControls())
    })
  }

  ngOnInit(): void {
    this.subscriptions.push(this.storesService.getWorkHours().subscribe(value => {
      if (value.results.length !== 0) {
        this.workHours = value

        const controls = this.workHours.results.map(workHour => {
          return {
            dayOfWeek: {
              name: this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[workHour.dayOfWeek] : workHour.dayOfWeek,
              value: workHour.dayOfWeek
            },
            openAt: parseTimeString(workHour.openAt),
            closeAt: parseTimeString(workHour.closeAt)
          }
        })

        this.form.controls.workHours.setValue(controls)
      }
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  private defaultWorkHoursControls() {
    const defaultTime: NgbTimeStruct = {
      hour: 9,
      minute: 0,
      second: 0
    }

    const controls = [
      this.formBuilder.group({
        dayOfWeek: this.formBuilder.group({
          value: new FormControl<DayOfWeek>(DayOfWeek.Sunday, { nonNullable: true }),
          name: new FormControl<string>(this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[DayOfWeek.Sunday] : DayOfWeek.Sunday, { nonNullable: true })
        }),
        openAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true }),
        closeAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true })
      }),
      this.formBuilder.group({
        dayOfWeek: this.formBuilder.group({
          value: new FormControl<DayOfWeek>(DayOfWeek.Monday, { nonNullable: true }),
          name: new FormControl<string>(this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[DayOfWeek.Monday] : DayOfWeek.Monday, { nonNullable: true })
        }),
        openAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true }),
        closeAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true })
      }),
      this.formBuilder.group({
        dayOfWeek: this.formBuilder.group({
          value: new FormControl<DayOfWeek>(DayOfWeek.Tuesday, { nonNullable: true }),
          name: new FormControl<string>(this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[DayOfWeek.Tuesday] : DayOfWeek.Tuesday, { nonNullable: true })
        }),
        openAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true }),
        closeAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true })
      }),
      this.formBuilder.group({
        dayOfWeek: this.formBuilder.group({
          value: new FormControl<DayOfWeek>(DayOfWeek.Wednesday, { nonNullable: true }),
          name: new FormControl<string>(this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[DayOfWeek.Wednesday] : DayOfWeek.Wednesday, { nonNullable: true })
        }),
        openAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true }),
        closeAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true })
      }),
      this.formBuilder.group({
        dayOfWeek: this.formBuilder.group({
          value: new FormControl<DayOfWeek>(DayOfWeek.Thursday, { nonNullable: true }),
          name: new FormControl<string>(this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[DayOfWeek.Thursday] : DayOfWeek.Thursday, { nonNullable: true })
        }),
        openAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true }),
        closeAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true })
      }),
      this.formBuilder.group({
        dayOfWeek: this.formBuilder.group({
          value: new FormControl<DayOfWeek>(DayOfWeek.Friday, { nonNullable: true }),
          name: new FormControl<string>(this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[DayOfWeek.Friday] : DayOfWeek.Friday, { nonNullable: true })
        }),
        openAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true }),
        closeAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true })
      }),
      this.formBuilder.group({
        dayOfWeek: this.formBuilder.group({
          value: new FormControl<DayOfWeek>(DayOfWeek.Saturday, { nonNullable: true }),
          name: new FormControl<string>(this.settingsService.currentLanguage === Language.AR ? this.arabicDaysOfWeekMap[DayOfWeek.Saturday] : DayOfWeek.Saturday, { nonNullable: true })
        }),
        openAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true }),
        closeAt: new FormControl<NgbTimeStruct>(defaultTime, { nonNullable: true })
      })
    ]

    controls.forEach(control => control.controls.dayOfWeek.controls.name.disable())

    return controls
  }

  saveChanges(event: SubmitEvent) {
    event.preventDefault()

    if (!this.form.valid) {
      this.form.markAllAsTouched()

      return
    }

    const request = {
      workHours: this.form.controls.workHours.controls.map(control => {
        return {
          dayOfWeek: control.controls.dayOfWeek.controls.value.value,
          openAt: parseTimeStructToIsoDate(control.controls.openAt.value),
          closeAt: parseTimeStructToIsoDate(control.controls.closeAt.value)
        }
      })
    }

    this.subscriptions.push(this.storesService.manageWorkHours(request).subscribe(response => {
      if ('validationMap' in response) {
        if (response.validationMap['WorkHours']) {
          this.toastService.addMessage({ message: response.validationMap['WorkHours'], severity: ToastSeverity.Danger })
        }

        const errorMessages = []

        for (let i = 0; i < 7; i++) {
          errorMessages.push(response.validationMap[`WorkHours[${i}].OpenAt`] || response.validationMap[`WorkHours[${i}].CloseAt`] || response.validationMap[`WorkHours[${i}].DayOfWeek`])
        }

        this.remoteErrorMessages = errorMessages

        return
      }

      this.toastService.addMessage({ message: response.message, severity: ToastSeverity.Success })

      this.ngOnInit()
    }))
  }
}
