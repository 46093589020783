<!--begin:Menu item-->
<div data-kt-menu-trigger="click" class="menu-item menu-accordion" [ngClass]="{'show': isOpen, 'hover': isOpen}">
    <!--begin:Menu link-->
    <span class="menu-link" (click)="toggle()">
        <span class="menu-icon h5">
            <i ngClass="{{iconClass}}"></i>
        </span>
        <span class="menu-title h5">{{title}}</span>
        <span class="menu-arrow h5"></span>
    </span>
    <!--end:Menu link-->
    <!--begin:Menu sub-->
    <div class="menu-sub menu-sub-accordion app-sidebar-menu-primary menu"
        [ngClass]="{'d-none' : !isOpen, 'overflow-hidden': !isOpen}">
        <div class="menu-item" *ngFor="let item of menuItems">
            <!--begin:Menu link-->
            <a class="menu-link" [routerLink]="item.url" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: item.matchExact}">
                <span class="menu-bullet">
                    <span class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">{{item.name}}</span>
            </a>
            <!--end:Menu link-->
        </div>
    </div>
    <!--end:Menu sub-->
</div>
<!--end:Menu item-->